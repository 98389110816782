import {FC, ReactNode} from 'react'

import useAuthState from '../hooks/useAuthState'

interface IAUthIndicator {
	children: ReactNode
}

const AuthIndicator: FC<IAUthIndicator> = ({children}) => {
	useAuthState()

	return <>{children}</>
}

export default AuthIndicator
