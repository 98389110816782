import {action, makeObservable, observable} from 'mobx'

import {getFormattedDate} from 'src/utils'

import HistoryStore, {IHistoryItem} from './History.store'

export default class Form1099History extends HistoryStore<docs.I1099Data> {
	protected documentType: TPdfDocumentsType = '1099'
	items: Map<string, IHistoryItem<docs.I1099Data>> = new Map()

	constructor() {
		super()
		makeObservable(
			this,
			{
				items: observable,
				searchQuery: observable,
				searchRange: observable,
				setSearchQuery: action,
				setSearchRange: action,
			},
			{deep: true},
		)
	}
	get historyItems() {
		const items = []
		for (const [key, value] of this.items.entries()) {
			items.push({
				payer: value.data?.payersName || '',
				recipient: value.data?.recipientsName || '',
				year: value.data?.year.getFullYear().toString() || '',
				income: value.data?.income || '',
				createdAt: getFormattedDate(value.createdAt, {
					locales: 'en-US',
					options: {month: 'numeric', day: 'numeric', year: 'numeric'},
				}),
				preview: `/user/history/1099/${key}`,
			})
		}
		return items
	}
}
