import {useState, useEffect, useRef} from 'react'

export function useExperiment(experimentId: string) {
	const [variant, setVariant] = useState<string>()
	const attempts = useRef(0)

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (attempts.current > 20) {
				clearInterval(intervalId)
			}
			const newVariant = window.posthog?.getFeatureFlag(experimentId) as string | undefined
			if (newVariant !== undefined) {
				// Set the variant to the state.
				setVariant(newVariant)
				clearInterval(intervalId)
			}
			attempts.current += 1
		}, 100)

		return () => clearInterval(intervalId)
	}, [])
	return variant
}
