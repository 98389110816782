import {captureException} from '@sentry/nextjs'
import {AxiosError} from 'axios'
import {makeAutoObservable} from 'mobx'
import {makePersistable} from 'mobx-persist-store'

import apiService from 'services/api.service'
import {posthogTrack, getStateFillingStatus, parseNumber} from 'src/utils'

export default class W2Store {
	isCalculationLoading = false
	errorMessage = ''
	form: Omit<docs.IW2Data, 'stateShort'> = {
		autoCalculator: 'true',
		year: new Date(),
		maritalStatus: 'SINGLE',
		dependentsAmount: '0',
		state: '',
		income: '',
		employeeSocialSecurityNumber: '',
		employersIdentificationNumber: '',
		wagesTipsOther: '',
		federalIncomeTax: '',
		companyName: '',
		companyAddress: '',
		companyCity: '',
		companyState: '',
		companyZip: '',
		socialSecurityWages: '',
		socialSecurityTax: '',
		medicareWages: '',
		medicareTax: '',
		socialSecurityTips: '',
		allocatedTips: '',
		employeeName: '',
		employeeAddress: '',
		employeeCity: '',
		employeeState: '',
		employeeZip: '',
		field12a: '',
		field12b: '',
		field12c: '',
		field12d: '',
		statutoryEmployee: '',
		retirementPlan: '',
		sickPay: '',
		companyStateId: '',
		stateWagesTips: '',
		stateIncomeTax: '',
		localWagesTips: '',
		localIncomeTax: '',
		localityName: '',
		illegalAgreement: '',
		terms: '',
	}

	constructor() {
		makeAutoObservable(this, {}, {deep: true})
		if (typeof window !== 'undefined') {
			makePersistable(this.form, {
				name: 'W2Store',
				properties: [
					'employersIdentificationNumber',
					'companyName',
					'companyAddress',
					'companyCity',
					'companyState',
					'companyZip',
				],
			})
		}

		this.calculate = this.calculate.bind(this)
		this.saveDraft = this.saveDraft.bind(this)
	}

	saveDraft(template: number, draftId?: string) {
		return apiService.saveDraft<docs.IW2Data>({
			type: 'w2',
			template,
			draftId,
			documentData: {
				...this.form,
				stateShort: this.stateShort,
			},
		})
	}

	setIncomes() {
		const form = this.form

		if (!this.isAutoCalculation && form.wagesTipsOther) return
		form.wagesTipsOther = form.income
		form.socialSecurityWages = form.income
		form.medicareWages = form.income
		form.stateWagesTips = form.income
	}

	get isAutoCalculation() {
		return this.form.autoCalculator === 'true'
	}

	get stateShort() {
		return this.form.state
	}

	get isInformationStepFilled() {
		const {income, state, maritalStatus, year} = this.form
		return !!income && !!state && !!maritalStatus && !!year
	}

	get isSecondStepFilled() {
		return (
			!!this.form.employeeName &&
			!!this.form.employeeAddress &&
			!!this.form.employeeCity &&
			!!this.form.employeeState &&
			!!this.form.employeeZip &&
			!!this.form.companyName &&
			!!this.form.companyAddress &&
			!!this.form.companyCity &&
			!!this.form.companyState &&
			!!this.form.companyZip &&
			!!this.form.companyStateId
		)
	}

	/** calculates taxes using the API and stores the result in this store class */
	async calculate() {
		const {autoCalculator} = this.form
		if (!this.isInformationStepFilled || autoCalculator !== 'true') return

		const getLastDayOfYear = (year: number) => {
			return new Date(year, 11, 31)
		}
		this.isCalculationLoading = true

		try {
			const res = await apiService.salaryCalculate({
				grossPay: parseNumber(this.form.income),
				dependents2020: parseNumber(this.form.dependentsAmount),
				grossPayType: 'ANNUALLY',
				payFrequency: 'ANNUAL',
				state: this.form.state || '',
				payDate: getLastDayOfYear(this.form.year.getFullYear()).getTime(),
				federalFilingStatusType: this.form.maritalStatus,
				grossPayYTD: 0,
				stateFillingStatus: getStateFillingStatus(
					this.form.maritalStatus,
				) as unknown as calculator.TStateFillingStatus,
			})

			if (res.data.ok && res.data.data) {
				const {federal, fica, state, medicare} = res.data.data
				this.form.federalIncomeTax = `${federal}`
				this.form.socialSecurityTax = `${fica}`
				this.form.medicareTax = `${medicare}`
				this.form.stateIncomeTax = `${state}`
			}
			this.errorMessage = ''
		} catch (error) {
			captureException(error)
			posthogTrack('[Error] Error in async operation', {
				message: (error as Error).message,
				flowFunction: 'W2.store.calculate',
			})
			this.errorMessage =
				(error as AxiosError<IApiResponseBase>).response?.data.error?.message ||
				'Something went wrong'
		} finally {
			this.isCalculationLoading = false
		}
	}
}
