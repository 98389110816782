export enum EBreakpoint {
	xs = 0,
	xxs = 375,
	sm = 576,
	md = 768,
	lg = 1024,
	xl = 1440,
	xxl = 1920,
}

export type TWindowsSize = {
	width: number
	height: number
}

export type TDeviceState = {
	isXs: boolean
	isXxs: boolean
	isSm: boolean
	isMd: boolean
	isLg: boolean
	isXl: boolean
	isXxl: boolean
}
