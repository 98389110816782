import React, {FC} from 'react'

import cn from 'classnames/bind'
import Link from 'next/link'
import {useRouter} from 'next/router'

import {IMenuItem} from 'src/types'

import s from './MenuFooter.module.scss'
const cx = cn.bind(s)

interface IMenuFooterProps {
	menu: IMenuItem[]
	className?: string
}

const MenuFooter: FC<IMenuFooterProps> = ({menu, className}) => {
	const router = useRouter()
	return (
		<nav className={cx('nav', className)}>
			<ul className={cx('menu', 'menu__left')} data-testid="footer-menu">
				{menu.map((item) => (
					<li className={cx('menu__item')} key={item.id}>
						<Link
							href={item.slug}
							className={cx('menu__link', {
								'menu__link--active': router.pathname === item.slug,
							})}>
							{item.title}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	)
}

MenuFooter.displayName = 'MenuFooter'

export default MenuFooter
