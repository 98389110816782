import {v4 as uuid} from 'uuid'
/** Object contains info about Posthog experiments */
const experiments = {
	pricing: {
		id: 'pricing_experiment',
	},
	chatbot: {
		id: 'chatbot',
	},
	redesignedBuilder: {
		id: 'paystub_builder',
	},
}

export const getDistinctID = (distinctIdFromCookies: string | undefined) => {
	return distinctIdFromCookies ?? uuid()
}

export default experiments
