const getOptions = <T>(p: readonly TOption<T>[]) => p

export const termsOptions = getOptions([
	{value: 'none', name: 'None'},
	{value: 'custom', name: 'Custom'},
	{value: 'on_receipt', name: 'On Receipt'},
	{value: '1', name: 'Next Day'},
	{value: '2', name: '2 Days'},
	{value: '3', name: '3 Days'},
	{value: '4', name: '4 Days'},
	{value: '5', name: '5 Days'},
	{value: '6', name: '6 Days'},
	{value: '7', name: '7 Days'},
	{value: '10', name: '10 Days'},
	{value: '14', name: '14 Days'},
	{value: '21', name: '21 Days'},
	{value: '30', name: '30 Days'},
	{value: '45', name: '45 Days'},
	{value: '60', name: '60 Days'},
	{value: '90', name: '90 Days'},
	{value: '120', name: '120 Days'},
	{value: '180', name: '180 Days'},
	{value: '365', name: '365 Days'},
] as const)
export type TTerms = typeof termsOptions[number]['value']

export const taxTypeOptions = getOptions([
	{value: 'on_total', name: 'On Total', isDefault: true},
	{value: 'deducted', name: 'Deducted'},
	{value: 'per_item', name: 'Per Item'},
	{value: 'none', name: 'None'},
] as const)
export type TTaxTypes = typeof taxTypeOptions[number]['value']

export const discountTypeOptions = getOptions([
	{value: 'none', name: 'None', isDefault: true},
	{value: 'percent', name: 'Percent'},
	{value: 'flat_amount', name: 'Flat Amount'},
] as const)
export type TDiscountTypes = typeof discountTypeOptions[number]['value']
