import {useEffect, useState} from 'react'

import {TWindowsSize, TDeviceState, EBreakpoint} from 'src/types/windowSize'
import {isBrowser} from 'src/utils/index'

const useWindowSize = (initialWidth = Infinity, initialHeight = Infinity) => {
	const [windowSize, setWindowSize] = useState<TWindowsSize>({
		width: isBrowser ? window.innerWidth : initialWidth,
		height: isBrowser ? window.innerHeight : initialHeight,
	})

	const getBreakpointValues = (width: number) => ({
		isXs: width > 0 && width < EBreakpoint.xxs,
		isXxs: width >= EBreakpoint.xxs && width < EBreakpoint.sm,
		isSm: width >= EBreakpoint.sm && width < EBreakpoint.md,
		isMd: width >= EBreakpoint.md && width < EBreakpoint.lg,
		isLg: width >= EBreakpoint.lg && width < EBreakpoint.xl,
		isXl: width >= EBreakpoint.xl && width < EBreakpoint.xxl,
		isXxl: width >= EBreakpoint.xxl,
	})

	const [device, setDevice] = useState<TDeviceState>(getBreakpointValues(windowSize.width))

	useEffect(() => {
		setDevice(getBreakpointValues(windowSize.width))
	}, [windowSize])

	useEffect(() => {
		const handleResize = () => {
			const newWidth = window.innerWidth
			const newHeight = window.innerHeight
			if (windowSize.width !== newWidth || windowSize.height !== newHeight) {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				})
			}
		}

		window.addEventListener('resize', handleResize)

		handleResize()

		return () => window.removeEventListener('resize', handleResize)
	}, [windowSize.height, windowSize.width])

	return {...windowSize, ...device}
}

export {useWindowSize}
