import React, {FC} from 'react'

import {useNProgress} from '@tanem/react-nprogress'
// import cn from 'classnames/bind'

import Bar from './Bar'
// import s from './ProgressBar.module.scss'
import Container from './ProgressBarContainer'

// const cx = cn.bind(s)

interface IProgressBarProps {
	className?: string
	/**
	 * When it's true, the progress is animates
	 */
	isAnimating: boolean
}

/**
 * Uses to display progress on the top of page.
 */
const ProgressBar: FC<IProgressBarProps> = ({className, isAnimating}) => {
	const {animationDuration, isFinished, progress} = useNProgress({
		animationDuration: 200,
		incrementDuration: 30,
		isAnimating,
	})

	return (
		<Container className={className} animationDuration={animationDuration} isFinished={isFinished}>
			<Bar animationDuration={animationDuration} progress={progress} />
		</Container>
	)
}

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar
