import {getDownloadURL, ref, uploadString} from 'firebase/storage'
import {makeAutoObservable} from 'mobx'
import {v4} from 'uuid'

import apiService from 'services/api.service'
import {storage} from 'src/lib/firebaseApp'
import {
	addDay,
	addMonth,
	exhaustiveCheck,
	generateRandomString,
	generateUniqueId,
	getOftenPaidNumberValue,
	getWorkDayBeforeWeekend,
} from 'src/utils'

import {
	TAutoCalculator,
	TEmployment,
	TOftenPaid,
	TPayment,
	TPayStubsNumber,
	TYesNo,
} from './PayStub.configs'
import PayStubDeductionsItemStore from './PayStubDeductionsItem.store'
import PayStubEarningsItemStore from './PayStubEarningsItem.store'
import PayStubItemStore from './PayStubItem.store'
import PayStubPrevYtd from './PayStubPrevYtd.store'

export default class PayStubStore {
	isCalculationPause = false
	shouldCalculateAfterPause = false
	isCalculationValid = true
	isStepFiveDirty = false
	experimentVariant: string | undefined = undefined

	autoCalculator: TAutoCalculator = 'true'
	employmentType: TEmployment = 'employee'
	paymentType: TPayment = 'hourly'
	private _oftenPaid: TOftenPaid = 'MONTHLY'
	prevYtd: TYesNo = 'false'
	hiredInPast52Weeks: TYesNo = 'false'
	payPeriodsWorked = '1'
	payStubsNumber: TPayStubsNumber | string = '1'
	items: PayStubItemStore[] = []
	prevYtdItem: PayStubPrevYtd
	coNumber = ''
	fileNumber = ''
	deptNumber = ''
	clockNumber = ''
	vchrNumber = ''
	isCalculationLoading = false
	errorMessage = ''
	checkNo = ''
	activeDataTemplate = 0
	privacy = false
	terms = false

	companyInfo: IPayStubCompanyInfo = {
		employerName: '',
		employerTelephone: '',
		streetAddress1: '',
		streetAddress2: '',
		city: '',
		state: '',
		zipCode: '',
		payrollLogo: '',
		employerLogo: '',
	}

	employeeInfo: IPayStubEmployeeInfo = {
		employeeName: '',
		employeeTelephone: '',
		employeeSocial: '',
		defaultEmployeeId: '',
		employeeId: '',
		streetAddress1: '',
		streetAddress2: '',
		city: '',
		state: '',
		zipCode: '',
		maritalStatus: 'SINGLE',
		dependentsAmount: '0',
		isDirectDeposit: null,
		last4Digits: '',
	}

	constructor() {
		makeAutoObservable(this, {}, {deep: true})
		this.items.push(new PayStubItemStore(this, 0))
		this.prevYtdItem = new PayStubPrevYtd(this, 0)
		this.coNumber = generateRandomString(3, '023456789abcdefghjkmnopqrstuvwxyz')
		this.fileNumber = generateRandomString(6)
		this.deptNumber = generateRandomString(6)
		this.clockNumber = generateRandomString(5)
		this.vchrNumber = '00000' + generateRandomString(5)
		this.checkNo = generateRandomString(4)
		this.employeeInfo.defaultEmployeeId = generateRandomString(3)

		this.saveDraft = this.saveDraft.bind(this)
		this.prepareDataForDraft = this.prepareDataForDraft.bind(this)
	}

	get isAutoCalculation() {
		return this.autoCalculator === 'true'
	}

	get isPrevYtd() {
		return this.prevYtd === 'true'
	}

	get isHiredInPast52Weeks() {
		return this.hiredInPast52Weeks === 'true'
	}

	set oftenPaid(v: TOftenPaid) {
		this._oftenPaid = v
		const oftenPaid = getOftenPaidNumberValue(this._oftenPaid)
		if (this.isHiredInPast52Weeks) {
			this.payPeriodsWorked = '1'
			this.setPayStubNumber('1')
		} else {
			if (Number(this.payStubsNumber) > oftenPaid) {
				this.setPayStubNumber(String(oftenPaid))
			}
		}

		this.setPayDatesAndPeriods(this.items[0].payDate, false)
	}

	get oftenPaid() {
		return this._oftenPaid
	}

	async uploadLogo(logo: string) {
		if (!logo) return ''
		const DAY_IN_MS = 1000 * 60 * 60 * 24
		const expiredTimestamp = Date.now() + DAY_IN_MS * 30
		const expiredDate = new Date(expiredTimestamp).toLocaleDateString('en-US').split('/').join('.')

		const id = v4()
		const logoRef = ref(storage, `logos-bucket/${expiredDate}/@paystub-${id}`)
		const res = await uploadString(logoRef, logo, 'data_url')
		const src = await getDownloadURL(res.ref)
		return src
	}

	async saveDraft(template: number, draftId?: string, theme?: TPdfDocumentsThemes) {
		const {prevYtdItemData, items} = this.prepareDataForDraft()

		const payrollLogo = await this.uploadLogo(this.companyInfo.payrollLogo)
		const employerLogo = await this.uploadLogo(this.companyInfo.employerLogo)

		return apiService.saveDraft<docs.IPaystubData>({
			type: 'paystub',
			template,
			draftId,
			theme,
			documentData: {
				autoCalculator: this.autoCalculator,
				employmentType: this.employmentType,
				paymentType: this.paymentType,
				oftenPaid: this.oftenPaid,
				prevYtd: this.prevYtd,
				hiredInPast52Weeks: this.hiredInPast52Weeks,
				payPeriodsWorked: this.payPeriodsWorked,
				payStubsNumber: this.payStubsNumber,
				prevYtdItem: {
					...prevYtdItemData,
					grossPayYTDTotal: this.prevYtdItem!.grossPayYTDTotal,
					netpayYTDTotal: this.prevYtdItem!.grossPayYTDTotal,
					deductionYTDTotal: this.prevYtdItem!.deductionYTDTotal,
				},
				coNumber: this.coNumber,
				fileNumber: this.fileNumber,
				deptNumber: this.deptNumber,
				clockNumber: this.clockNumber,
				vchrNumber: this.vchrNumber,
				checkNo: this.checkNo,
				companyInfo: {
					...this.companyInfo,
					payrollLogo,
					employerLogo,
				},
				employeeInfo: this.employeeInfo,
				items,
			},
		})
	}

	prepareDataForDraft() {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const {rootStub, ...prevYtdItemData} = this.prevYtdItem as PayStubPrevYtd

		const items = this.items.map((item: PayStubItemStore) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const {rootStub, earnings, deductions, customEarnings, customDeductions, ...itemData} = item

			const earningsItem = Object.entries(earnings).map(([earningKey, earningValue]) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const {rootStub, parent, ...earningItemData} = earningValue as PayStubEarningsItemStore
				const earning: IEarningsStub = {
					...earningItemData,
					total: earningValue?.total || '',
					ytd: earningValue?.ytd || '',
				}

				return [earningKey, earning]
			})
			const earningsObj = Object.fromEntries(earningsItem)

			const deductionsItem = Object.entries(deductions).map(([deductionKey, deductionValue]) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const {rootStub, ...earningItemData} = deductionValue as PayStubDeductionsItemStore
				const deduction: IDeductionsStub = {
					...earningItemData,
					total: deductionValue?.total || '',
					ytd: deductionValue?.ytd || '',
				}
				return [deductionKey, deduction]
			})
			const deductionsObj = Object.fromEntries(deductionsItem)

			const customEarningsItems = customEarnings.map((customEarning) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const {rootStub, parent, ...customEarningData} = customEarning as PayStubEarningsItemStore
				const earning: IEarningsStub = {
					...customEarningData,
					total: customEarning.total,
					ytd: customEarning.ytd,
				}
				return earning
			})

			const customDeductionsItems = customDeductions.map((customDeduction) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const {rootStub, ...customEarningData} = customDeduction as PayStubDeductionsItemStore
				const deduction: IDeductionsStub = {
					...customEarningData,
					total: customDeduction.total,
					ytd: customDeduction.ytd,
				}
				return deduction
			})

			const documentItem: IPayStubItem = {
				...itemData,
				earnings: earningsObj,
				deductions: deductionsObj,
				customEarnings: customEarningsItems,
				customDeductions: customDeductionsItems,
				grossPayTotal: item.grossPayTotal,
				grossPayYTDTotal: item.grossPayYTDTotal,
				deductionTotal: item.deductionTotal,
				deductionYTDTotal: item.deductionYTDTotal,
				netpayTotal: item.netpayTotal,
				netpayYTDTotal: item.netpayYTDTotal,
				payDate: item.payDate,
				payPeriod: {
					to: item.payPeriod.to,
					from: item.payPeriod.from,
				},
			}
			return documentItem
		})

		return {prevYtdItemData, items}
	}

	dirtyStepFive() {
		this.isStepFiveDirty = true
	}

	pauseCalculation = () => {
		this.isCalculationPause = true
	}

	resumeCalculation = () => {
		this.isCalculationPause = false
	}

	/** Set the isCalculationValid field to true  */
	validateCalculation = () => {
		this.isCalculationValid = true
	}

	/** Set the isCalculationValid field to false  */
	unValidateCalculation = () => {
		if (!this.isAutoCalculation) return
		this.isCalculationValid = false
	}

	setIfShouldBeCalculated = (v: boolean) => {
		this.shouldCalculateAfterPause = this.isCalculationPause ? v : false
	}

	calculateItems = () => {
		if (this.isCalculationPause) return
		this.items.map((item) => {
			item.calculate()
		})
	}

	/** Set the employeeSocial field to the right format XXX-XX-value **/
	setEmployeeInfo = (value: string) => {
		if (value.length === 1) {
			this.employeeInfo.employeeSocial = 'XXX-XX-' + value
			return
		}

		const numValue = value.slice(7, 11)
		if (numValue === '' && value.length > 1) {
			this.employeeInfo.employeeSocial = ''
			return
		}
		this.employeeInfo.employeeSocial = 'XXX-XX-' + numValue.slice(0, 4)
	}

	/** Add custom earning field to all paystubs and previous ytd **/
	addEarningsItem = () => {
		const uniqueId = generateUniqueId()
		this.items.forEach((item, index) => {
			item.customEarnings.push(
				new PayStubEarningsItemStore(this, item, index, '', '', uniqueId, true),
			)
		})

		this.prevYtdItem?.customEarnings.push({
			id: uniqueId,
			name: '',
			key: '',
			isCustom: true,
			ytd: '0.00',
		})
	}

	/** Remove custom earning field from all paystubs and previous ytd **/
	removeEarningsItem = (id: number) => {
		this.items.forEach((item) => {
			const filteredEarnings = item.customEarnings.filter((earning) => earning.id !== id)
			item.customEarnings = filteredEarnings
		})

		const filteredPrevYtdEarnings = this.prevYtdItem!.customEarnings.filter(
			(earning) => earning.id !== id,
		)
		this.prevYtdItem!.customEarnings = filteredPrevYtdEarnings
	}

	/** Set custom earning name to the custom earning field **/
	setEarningsCustomName = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
		const newValue = e.target.value
		this.items.forEach((item) => {
			const filteredEarnings = item.customEarnings.filter((earning) => {
				if (earning.id === id) {
					earning.name = newValue
					earning.key = newValue.toLocaleLowerCase()
				}
				return earning
			})

			item.customEarnings = filteredEarnings
		})

		const filteredPrevYtdEarnings = this.prevYtdItem!.customEarnings.filter((earning) => {
			if (earning.id === id) {
				earning.name = newValue
				earning.key = newValue.toLocaleLowerCase()
			}
			return earning
		})

		this.prevYtdItem!.customEarnings = filteredPrevYtdEarnings
	}

	/** Add custom deduction field to all paystubs and previous ytd **/
	addDeductionsItem = () => {
		const uniqueId = generateUniqueId()
		this.items.forEach((item, index) => {
			item.customDeductions.push(
				new PayStubDeductionsItemStore(this, index, '', '', '0.00', uniqueId, true),
			)
		})

		this.prevYtdItem?.customDeductions.push({
			id: uniqueId,
			name: '',
			key: '',
			isCustom: true,
			ytd: '0.00',
		})
	}

	/** Remove custom deduction field from all paystubs and previous ytd **/
	removeDeductionsItem = (id: number) => {
		this.items.forEach((item) => {
			const filteredDeductions = item.customDeductions.filter((deduction) => deduction.id !== id)
			item.customDeductions = filteredDeductions
		})

		const filteredPrevYtdDeductions = this.prevYtdItem!.customDeductions.filter(
			(deduction) => deduction.id !== id,
		)
		this.prevYtdItem!.customDeductions = filteredPrevYtdDeductions
	}

	/** Set custom deduction name to the custom deduction field **/
	setDeductionsCustomName = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
		const newValue = e.target.value
		this.items.forEach((item) => {
			const filteredDeductions = item.customDeductions.filter((deduction) => {
				if (deduction.id === id) {
					deduction.name = newValue
					deduction.key = newValue.toLocaleLowerCase()
				}
				return deduction
			})

			item.customDeductions = filteredDeductions
		})

		const filteredPrevYtdDeductions = this.prevYtdItem!.customDeductions.filter((deduction) => {
			if (deduction.id === id) {
				deduction.name = newValue
				deduction.key = newValue.toLocaleLowerCase()
			}
			return deduction
		})

		this.prevYtdItem!.customDeductions = filteredPrevYtdDeductions
	}

	/** Set index for current data template in preview **/
	setActiveDataTemplate = (value: number | ((activeTemplate: number) => number)) => {
		if (typeof value === 'function') {
			this.activeDataTemplate = value(this.activeDataTemplate)
			return
		}
		this.activeDataTemplate = value
	}

	/** Set number of paystubs and сreate paystubs **/
	setPayStubNumber = (value: string) => {
		this.payStubsNumber = value
		this.setActiveDataTemplate(0)
		const diff = Number(this.payStubsNumber) - this.items.length
		const firstItem = this.items[0]

		if (Number(this.payStubsNumber) > this.items.length) {
			for (let i = 0; i < diff; i++) {
				const prevId = this.items[this.items.length - 1].itemId
				const newItem = new PayStubItemStore(this, prevId + 1)
				firstItem.customEarnings.forEach((earnings) => {
					const uniqueId = earnings.id
					const name = earnings.name
					const key = earnings.key

					newItem.customEarnings.push(
						new PayStubEarningsItemStore(this, newItem, prevId + 1, name, key, uniqueId, true),
					)
				})

				firstItem.customDeductions.forEach((deductions) => {
					const uniqueId = deductions.id
					const name = deductions.name
					const key = deductions.key
					const total = deductions.total

					newItem.customDeductions.push(
						new PayStubDeductionsItemStore(this, prevId + 1, name, key, total, uniqueId, true),
					)
				})

				this.items.push(newItem)
			}
			this.setPayDatesAndPeriods(firstItem.payDate, false)
		} else {
			for (let i = 0; i < Math.abs(diff); i++) {
				this.items.pop()
			}
		}
	}

	/** Set number of pay periods have employee worked **/
	setPayPeriodsWorked = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value as any
		this.payPeriodsWorked = value

		if (Number(this.payStubsNumber) > Number(this.payPeriodsWorked)) {
			this.setPayStubNumber(this.payPeriodsWorked)
		}
	}

	/** Set payPeriod.to for paystub which index set as params.
	 * payPeriod and payDate will be change according to oftenPaid
	 * value in all paystubs if index is "0" and isAutoCalculation is "true".
	 *
	 * @param {Date} date value from payPeriod.to input
	 * @param {number} index number of paystub item where handle payPeriod.to field
	 */
	setPayPeriodTo = (date: Date, index: number) => {
		if (this.isAutoCalculation) {
			index === 0
				? this.setPayDatesAndPeriods(date, false)
				: (this.items[index].payPeriod.to = date)
		} else {
			this.items[index].payPeriod.to = date
		}
	}

	/** Set payPeriod.from for paystub which index set as params.
	 * payPeriod and payDate will be change according to oftenPaid
	 * value in all paystubs if index is "0" and isAutoCalculation is "true".
	 *
	 * @param {Date} date value from payPeriod.from input
	 * @param {number} index number of paystub item where handle payPeriod.from field
	 */
	setPayPeriodFrom = (date: Date, index: number) => {
		if (this.isAutoCalculation) {
			index === 0
				? this.setPayDatesAndPeriods(date, true)
				: (this.items[index].payPeriod.from = date)
		} else {
			this.items[index].payPeriod.from = date
		}
	}

	/** Set payDate for paystub which index set as params.
	 * payDate will be change according to oftenPaid value
	 * in all paystubs if index is "0" and isAutoCalculation is "true".
	 *
	 * @param {Date} date value from payDate input
	 * @param {number} index number of paystub item where handle payDate field
	 */
	setPayDate = (date: Date, index: number) => {
		if (this.isAutoCalculation) {
			index === 0 ? this.setPayDates(date) : (this.items[index].payDate = date)
		} else {
			this.items[index].payDate = date
		}
	}

	/** Set payDate for each paystubs.
	 * If oftenPaid is "DAILY" payDate should be only work day (weekends are excluded)
	 * @param {Date} date
	 */
	setPayDates = (date: Date) => {
		this.items.forEach((item, index) => {
			if (index === 0) {
				item.payDate = new Date(date)
			} else {
				const previousPayPeriodFrom = new Date(this.items[index - 1].payDate)
				item.payDate = this.getNextPaymentDate(previousPayPeriodFrom)
			}
		})
	}

	/** Set and calculate payPeriod.from, payPeriod.to and payDate for each paystubs.
	 *
	 * @param {Date} date
	 * @param {boolean} isPayPeriodFrom "true" if handle payPeriod.from input
	 */
	setPayDatesAndPeriods = (date: Date, isPayPeriodFrom: boolean) => {
		this.items.forEach((item, index) => {
			if (index === 0) {
				if (isPayPeriodFrom) {
					item.payPeriod.from = new Date(date)
					item.payPeriod.to = this.getDayByOftenPaid(new Date(item.payPeriod.from), true)
					item.payDate = new Date(item.payPeriod.to)
				} else {
					item.payDate = new Date(date)
					item.payPeriod.to = new Date(item.payDate)
					item.payPeriod.from = this.getDayByOftenPaid(new Date(item.payDate), false)
				}
			} else {
				const previousPayPeriodFrom = new Date(this.items[index - 1].payPeriod.from)
				if (this.oftenPaid === 'DAILY') {
					const workDay = getWorkDayBeforeWeekend(addDay(previousPayPeriodFrom, -1))
					item.payDate = workDay
				} else {
					item.payDate = addDay(previousPayPeriodFrom, -1)
				}
				item.payPeriod.from = this.getDayByOftenPaid(new Date(item.payDate), false)
				item.payPeriod.to = new Date(item.payDate)
			}
		})
	}

	/** Get date according to the oftenPaid value.
	 *
	 * @param {Date} date
	 * @param {boolean} isFrom
	 * @returns {Date} right date according to the oftenPaid value
	 */
	getDayByOftenPaid = (date: Date, isFrom: boolean): Date => {
		switch (this.oftenPaid) {
			case 'DAILY':
				return new Date(date)
			case 'WEEKLY':
				return isFrom ? addDay(date, 6) : addDay(date, -6)
			case 'BI_WEEKLY':
				return isFrom ? addDay(date, 13) : addDay(date, -13)
			case 'SEMI_MONTHLY':
				return isFrom ? addDay(date, 14) : addDay(date, -14)
			case 'MONTHLY':
				isFrom ? addMonth(date, 1) : addMonth(date, -1)
				return isFrom ? addDay(date, -1) : addDay(date, 1)
			case 'QUARTERLY':
				isFrom ? addMonth(date, 3) : addMonth(date, -3)
				return isFrom ? addDay(date, -1) : addDay(date, 1)
			case 'SEMI_ANNUAL':
				isFrom ? addMonth(date, 6) : addMonth(date, -6)
				return isFrom ? addDay(date, -1) : addDay(date, 1)
			case 'ANNUAL':
				return isFrom ? addDay(date, 364) : addDay(date, -364)
			default:
				const _exhaustiveCheck: never = this.oftenPaid
				exhaustiveCheck(_exhaustiveCheck)
		}
	}

	/** Get next payment date according to the oftenPaid value.
	 *
	 * @param {Date} date
	 * @returns {Date} right date according to the oftenPaid value
	 */
	getNextPaymentDate = (date: Date): Date => {
		switch (this.oftenPaid) {
			case 'DAILY':
				return getWorkDayBeforeWeekend(addDay(date, -1))
			case 'WEEKLY':
				return addDay(date, -7)
			case 'BI_WEEKLY':
				return addDay(date, -14)
			case 'SEMI_MONTHLY':
				return addDay(date, -15)
			case 'MONTHLY':
				return addMonth(date, -1)
			case 'QUARTERLY':
				return addMonth(date, -3)
			case 'SEMI_ANNUAL':
				return addMonth(date, -6)
			case 'ANNUAL':
				return addDay(date, -364)
			default:
				const _exhaustiveCheck: never = this.oftenPaid
				exhaustiveCheck(_exhaustiveCheck)
		}
	}

	/** Get current period for "WEEKLY", "BI_WEEKLY", "SEMI_MONTHLY" options.
	 * "WEEKLY" has 52 periods
	 * "BI_WEEKLY" has 26 periods
	 * "SEMI_MONTHLY" has 24 periods
	 *
	 * @param {number} dayOfYear
	 * @returns {number} current period
	 */
	getPeriod(dayOfYear: number): number {
		const periodDays =
			this.oftenPaid === 'SEMI_MONTHLY' ? 15 : this.oftenPaid === 'BI_WEEKLY' ? 14 : 7
		const period = Math.ceil(dayOfYear / periodDays)
		return period > getOftenPaidNumberValue(this.oftenPaid)
			? getOftenPaidNumberValue(this.oftenPaid)
			: period
	}

	/** Get current period for "QUARTERLY" option.
	 * "QUARTERLY" has 4 periods
	 *
	 * @param {Date} payDate
	 * @returns {number} current period
	 */
	getQuarterlyPeriod(payDate: Date): number {
		const month = payDate.getMonth() + 1
		if (month <= 3) {
			return 1
		} else if (month > 3 && month <= 6) {
			return 2
		} else if (month > 6 && month <= 9) {
			return 3
		} else {
			return 4
		}
	}

	/** Get current period for "SEMI_ANNUAL" option.
	 * "SEMI_ANNUAL" has 2 periods
	 *
	 * @param {Date} payDate
	 * @returns {number} current period
	 */
	getSemiAnnuallyPeriod(payDate: Date): number {
		const month = payDate.getMonth() + 1
		if (month <= 6) {
			return 1
		} else {
			return 2
		}
	}
}
