import axios, {AxiosResponse} from 'axios'

import {auth} from 'src/lib/firebaseApp'
import {handleDates} from 'src/utils'

axios.defaults.baseURL = '/api'

axios.interceptors.response.use((originalResponse) => {
	handleDates(originalResponse.data)
	return originalResponse
})

class ApiService {
	logout = () => axios.get('/logout')

	private _getToken = () => {
		return auth.currentUser?.getIdToken()
	}

	signIn = async (body: Api.signIn.IBody) => {
		return axios.post<Api.signIn.IResponse, AxiosResponse<Api.signIn.IResponse>, Api.signIn.IBody>(
			'/sign-in',
			body,
		)
	}

	signUp = async (body: Api.signUp.IBody) => {
		return axios.post<Api.signUp.IResponse, AxiosResponse<Api.signUp.IResponse>, Api.signUp.IBody>(
			'/sign-up',
			body,
		)
	}

	reply = async (body: Api.replyBlog.IBody) => {
		return axios.post<
			Api.replyBlog.IResponse,
			AxiosResponse<Api.replyBlog.IResponse>,
			Api.replyBlog.IBody
		>('/reply', body)
	}

	feedback = async (body: Api.feedback.IBody) => {
		return axios.post<
			Api.feedback.IResponse,
			AxiosResponse<Api.feedback.IResponse>,
			Api.feedback.IBody
		>('/feedback', body)
	}

	contact = async (body: Api.contact.IBody) => {
		return axios.post<
			Api.contact.IResponse,
			AxiosResponse<Api.contact.IResponse>,
			Api.contact.IBody
		>('/contact', body)
	}

	notifyPasswordUpdated = async () => {
		const token = await this._getToken()
		return axios.get<
			Api.notifyPasswordUpdated.IResponse,
			AxiosResponse<Api.notifyPasswordUpdated.IResponse>
		>('/profile/notify-password-updated', {
			headers: {
				Authorization: token || '',
			},
		})
	}

	updateProfile = async (body: Api.updateProfile.IBody) => {
		const token = await this._getToken()
		return axios.post<
			Api.updateProfile.IResponse,
			AxiosResponse<Api.updateProfile.IResponse>,
			Api.updateProfile.IBody
		>('/profile/update-profile', body, {
			headers: {
				Authorization: token || '',
			},
		})
	}

	getProfileData = async () => {
		const token = await this._getToken()
		return axios.get<
			Api.getProfileData.IResponse,
			AxiosResponse<Api.getProfileData.IResponse, Api.getProfileData.IBody>
		>('/profile/profile-data', {
			headers: {
				Authorization: token || '',
			},
		})
	}

	documentsGenerate = async (body: Api.documentsGenerate.IBody) => {
		const token = await this._getToken()
		const res = await axios.post<
			Api.documentsGenerate.IResponse,
			AxiosResponse<Api.documentsGenerate.IResponse>,
			Api.documentsGenerate.IBody
		>('/documents/generate-pdf', body, {
			responseType: 'blob',
			headers: {Authorization: token || ''},
		})
		this._handleBlob(res)
	}

	xlsxGenerate = async (body: Api.xlsxGenerate.IBody) => {
		const token = await this._getToken()
		const res = await axios.post<
			Api.xlsxGenerate.IResponse,
			AxiosResponse<Api.xlsxGenerate.IResponse>,
			Api.xlsxGenerate.IBody
		>('/documents/generate-xlsx', body, {
			responseType: 'blob',
			headers: {Authorization: token || ''},
		})

		this._handleBlob(res)
	}

	saveDraft = async <T extends docs.TDocumentData>(body: Api.saveDraft.IBody<T>) => {
		const token = await this._getToken()
		return axios.post<
			Api.saveDraft.IResponse,
			AxiosResponse<Api.saveDraft.IResponse>,
			Api.saveDraft.IBody
		>('/save-draft', body, {
			headers: {
				Authorization: token || '',
			},
		})
	}

	salaryCalculate = async (body: Api.salaryCalc.IBody) => {
		return axios.post<
			Api.salaryCalc.IResponse,
			AxiosResponse<Api.salaryCalc.IResponse>,
			Api.salaryCalc.IBody
		>('/salary-calculate', body)
	}

	getFilteredDocuments = async <
		T extends docs.TDocumentData,
		F extends Api.profileDocuments.TFilter,
	>(
		type: TPdfDocumentsType,
		body: Api.profileDocuments.filter.IBody<F>,
	) => {
		const token = await this._getToken()
		const res = await axios.post<
			Api.profileDocuments.filter.IResponse<T>,
			AxiosResponse<Api.profileDocuments.filter.IResponse<T>>,
			Api.profileDocuments.filter.IBody
		>(`/profile/documents/filter/${type}`, body, {
			headers: {
				Authorization: token || '',
			},
		})
		return res.data
	}

	getUserDocumentById = async <T>(id: string, body: Api.profileDocuments.item.IBody) => {
		const token = await this._getToken()
		return axios.post<
			Api.profileDocuments.item.IResponse<T>,
			AxiosResponse<Api.profileDocuments.item.IResponse<T>>,
			Api.profileDocuments.item.IBody
		>(`/profile/documents/${id}`, body, {
			headers: {
				Authorization: token || '',
			},
		})
	}

	stopSubscription = async () => {
		const token = await this._getToken()
		return axios.post<
			Api.stopSubscription.IResponse,
			AxiosResponse<Api.stopSubscription.IResponse>,
			Api.stopSubscription.IBody
		>(
			'/stop-subscription',
			{},
			{
				headers: {
					Authorization: token || '',
				},
			},
		)
	}

	createOrder = async (data: Api.createOrder.IBody) => {
		return (
			await axios.post<
				Api.createOrder.IResponse,
				AxiosResponse<Api.createOrder.IResponse>,
				Api.createOrder.IBody
			>('/payments/create-order', data)
		).data
	}

	private _handleBlob<R extends Blob>(response: AxiosResponse<R, any>) {
		const disposition = response.headers['content-disposition']

		let filename = ''
		if (disposition && disposition.indexOf('attachment') !== -1) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
			const matches = filenameRegex.exec(disposition)
			if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
		}

		const blob = response.data

		const URL = window.URL || window.webkitURL
		const downloadUrl = URL.createObjectURL(blob)

		if (filename) {
			const a = document.createElement('a')
			if (typeof a.download === 'undefined') {
				window.location.href = downloadUrl
			} else {
				a.href = downloadUrl
				a.download = filename
				document.body.appendChild(a)
				a.click()
			}
		} else {
			window.location.href = downloadUrl
		}

		setTimeout(() => URL.revokeObjectURL(downloadUrl), 100)
	}

	getChatHistory = async (id: string) => {
		return (
			await axios.post<
				Api.chatHistory.IResponse,
				AxiosResponse<Api.chatHistory.IResponse>,
				Api.chatHistory.IBody
			>(`/chat-history`, {id})
		).data
	}
}

export default new ApiService()
