import {action, makeObservable, observable} from 'mobx'

import {getFormattedDate} from 'src/utils'

import HistoryStore, {IHistoryItem} from './History.store'

export default class PaystubHistory extends HistoryStore<docs.IPaystubData> {
	items: Map<string, IHistoryItem<docs.IPaystubData>> = new Map()
	protected documentType: TPdfDocumentsType = 'paystub'

	constructor() {
		super()
		makeObservable(
			this,
			{
				items: observable,
				searchQuery: observable,
				searchRange: observable,
				setSearchQuery: action,
				setSearchRange: action,
			},
			{deep: true},
		)
	}

	get historyItems() {
		const items = []
		for (const [key, value] of this.items.entries()) {
			items.push({
				companyName: value.data?.companyInfo.employerName || '',
				employeeName: value.data?.employeeInfo.employeeName || '',
				createdAt: getFormattedDate(value.createdAt, {
					locales: 'en-US',
					options: {month: 'numeric', day: 'numeric', year: 'numeric'},
				}),
				quantity: value.data?.items.length.toString() || '',
				preview: `/user/history/paystub/${key}`,
			})
		}
		return items
	}
}
