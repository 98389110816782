import React, {FC} from 'react'

import cn from 'classnames/bind'
import {observer} from 'mobx-react-lite'
import {useRouter} from 'next/router'

import {IMenuItem} from 'src/types'
import {useAuthStore} from 'src/utils/contexts/RootStore.context'

import s from './MenuPrimary.module.scss'
import LoggedUserMenu from '../LoggedUserMenu'
import MenuPrimaryItem from '../MenuPrimaryItem'

const cx = cn.bind(s)

interface IPrimaryMenuProps {
	className?: string
	menuItems: IMenuItem[]
	hasLinks?: boolean
}

const MenuPrimary: FC<IPrimaryMenuProps> = ({menuItems, hasLinks = true, className}) => {
	const authStore = useAuthStore()

	const router = useRouter()
	const userLinkReg = /\/user\//

	return (
		<nav className={cx('primary-nav', className)}>
			<ul className={cx('primary-list')}>
				{hasLinks &&
					menuItems.map((item) => (
						<MenuPrimaryItem
							key={item.id}
							href={item.slug}
							title={item.title}
							className={cx('primary-list__item')}
						/>
					))}

				{authStore.isLoggedIn ? (
					<MenuPrimaryItem
						title="Account"
						className={cx('primary-list__item')}
						dropdown={<LoggedUserMenu />}
						isActiveLink={userLinkReg.test(router.pathname)}
					/>
				) : (
					<MenuPrimaryItem href="/auth/login" title="Log in" className={cx('primary-list__item')} />
				)}
			</ul>
		</nav>
	)
}

MenuPrimary.displayName = 'MenuPrimary'

export default observer(MenuPrimary)
