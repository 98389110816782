import {makeAutoObservable, runInAction} from 'mobx'

import {
	getStringValue,
	parseNumber,
	getOftenPaidNumberValue,
	getDayOfYear,
	getWorkingDays,
} from 'src/utils'

import PayStubStore from './PayStub.store'
import PayStubItemStore from './PayStubItem.store'

export default class PayStubEarningsItemStore implements IEarningsStub {
	rootStub: PayStubStore
	parent: PayStubItemStore
	id
	itemId
	name = ''
	key = ''
	isCustom = false
	rate = '0.00'
	hours = '0.00'
	private _defaultRate = '0.00'
	private _defaultHours = '0.00'
	private _total = '0.00'
	private _ytd = '0.00'

	constructor(
		rootStub: PayStubStore,
		parent: PayStubItemStore,
		itemId: number,
		name: string,
		key: string,
		id: number,
		isCustom: boolean,
	) {
		makeAutoObservable(this, {rootStub: false}, {deep: true})
		this.rootStub = rootStub
		this.parent = parent
		this.itemId = itemId
		this.name = name
		this.key = key
		this.id = id
		this.isCustom = isCustom

		/** Set default values for rate, hours **/
		if (this.rootStub.items.length === 0) {
			this._defaultRate =
				this.key === 'regular' || this.key === 'salary' || this.key === 'contractor'
					? '10.00'
					: '0.00'
			this._defaultHours = key === 'regular' || key === 'contractor' ? '40.00' : '0.00'
		} else {
			const firstItem = this.rootStub.items[0]
			if (this.key === 'regular' || this.key === 'salary' || this.key === 'contractor') {
				this._defaultHours = firstItem.earnings[this.key as TEarningsTypes]?.hours || '0.00'
			}
			this._defaultRate = firstItem.earnings[this.key as TEarningsTypes]?.rate || '0.00'

			if (isCustom) {
				const filteredItem = firstItem.customEarnings.find((earning) => earning.id === this.id)
				this._defaultRate = filteredItem?.rate || '0.00'
			}
		}
		this.rate = this._defaultRate
		this.hours = this._defaultHours
	}

	/** Gets calculated total when isAutoCalculation is "true".
	 * Otherwise gets private value _total.
	 *
	 * @returns {string} total
	 */
	get total() {
		runInAction(() => {
			this.rootStub.isAutoCalculation ? (this._total = this.getCalculateTotal()) : null
		})
		return this.rootStub.isAutoCalculation ? this.getCalculateTotal() : this._total
	}

	/** Sets value for private field _total. **/
	set total(v) {
		this._total = v
	}

	/** Gets calculated ytd when isAutoCalculation is "true".
	 * Otherwise gets private value _ytd.
	 *
	 * @returns {string} ytd
	 */
	get ytd() {
		runInAction(() => {
			this.rootStub.isAutoCalculation ? (this._ytd = this.getCalculatedYTDTotal()) : null
		})
		return this.rootStub.isAutoCalculation ? this.getCalculatedYTDTotal() : this._ytd
	}

	/** Sets value for private field _ytd. **/
	set ytd(v) {
		this._ytd = v
	}

	/** Calculates the total by multiplying rate and hours.
	 * If earning is "salary" the total equals rate divided often paid periods.
	 * If earning is "tips" the total equals rate.
	 * @returns {string} total
	 */
	getCalculateTotal() {
		let value = '0,00'

		if (this.key === 'tips') {
			value = this.rate
		} else if (this.key === 'salary') {
			value = getStringValue(
				parseNumber(this.rate) / getOftenPaidNumberValue(this.rootStub.oftenPaid),
			)
		} else {
			value = getStringValue(parseNumber(this.rate) * parseNumber(this.hours))
		}

		return value
	}

	/** Calculates ytd total (for earning) according to often paid options.
	 * @returns {string} ytd total
	 */
	getCalculatedYTDTotal() {
		switch (this.rootStub.oftenPaid) {
			case 'DAILY':
				return this.getCalculatedDailyYTD()
			case 'WEEKLY':
				return this.getCalculateYTD()
			case 'BI_WEEKLY':
				return this.getCalculateYTD()
			case 'SEMI_MONTHLY':
				return this.getCalculateYTD()
			case 'MONTHLY':
				return this.getCalculatedMonthlyYTD()
			case 'QUARTERLY':
				return this.getCalculatedQuarterlyYTD()
			case 'SEMI_ANNUAL':
				return this.getCalculatedSemiAnnuallyYTD()
			case 'ANNUAL':
				return this.getCalculatedAnnuallyYTD()
			default:
				return '0.00'
		}
	}

	/** Calculates ytd total for each paystub if oftenPaid is "DAILY".
	 * Ytd total equals working days multiply to total for last paystub.
	 * If isPrevYtd is "true" and work days doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedDailyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customEarning = item.customEarnings.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customEarning?.total)
					: parseNumber(item.earnings[this.key as TEarningsTypes]?.total)

				if (index === 0) {
					const fullYear = item.payDate.getFullYear()
					const workingDays = getWorkingDays(new Date(fullYear, 0, 1), new Date(item.payDate))
					this.rootStub.isPrevYtd && workingDays !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(Math.round(workingDays) * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "WEEKLY" or "BI_WEEKLY" or "SEMI_MONTHLY".
	 * Ytd total equals current period multiply to total for last paystub.
	 * If isPrevYtd is "true" and current period doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculateYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customEarning = item.customEarnings.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customEarning?.total)
					: parseNumber(item.earnings[this.key as TEarningsTypes]?.total)

				if (index === 0) {
					const dayOfYear = getDayOfYear(new Date(arr[index].payDate))
					const period = this.rootStub.getPeriod(dayOfYear)

					this.rootStub.isPrevYtd && period !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(period * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "MONTHLY".
	 * Ytd total equals current month multiply to total for last paystub.
	 * If isPrevYtd is "true" and current month doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedMonthlyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customEarning = item.customEarnings.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customEarning?.total)
					: parseNumber(item.earnings[this.key as TEarningsTypes]?.total)

				if (index === 0) {
					const month = item.payDate.getMonth() + 1
					this.rootStub.isPrevYtd && month !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(month * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "QUARTERLY".
	 * Ytd total equals current period multiply to total for last paystub.
	 * If isPrevYtd is "true" and current period doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedQuarterlyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customEarning = item.customEarnings.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customEarning?.total)
					: parseNumber(item.earnings[this.key as TEarningsTypes]?.total)
				const period = this.rootStub.getQuarterlyPeriod(new Date(arr[index].payDate))

				if (index === 0) {
					this.rootStub.isPrevYtd && period !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(period * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(period * total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "SEMI_ANNUAL".
	 * Ytd total equals current period multiply to total for last paystub.
	 * If isPrevYtd is "true" and current period doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedSemiAnnuallyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customEarning = item.customEarnings.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customEarning?.total)
					: parseNumber(item.earnings[this.key as TEarningsTypes]?.total)
				const period = this.rootStub.getSemiAnnuallyPeriod(new Date(arr[index].payDate))

				if (index === 0) {
					this.rootStub.isPrevYtd && period !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(period * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(period * total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total if isPrevYtd is "true".
	 * @returns {string} ytd total
	 */
	calculateWithPrevYtd(item: IPayStubItem) {
		const customPrevEarning = this.rootStub.prevYtdItem?.customEarnings.find(
			(item) => item.id === this.id,
		)
		const customEarning = item.customEarnings.find((item) => item.id === this.id)

		return this.isCustom
			? parseNumber(customPrevEarning?.ytd) + parseNumber(customEarning?.total)
			: parseNumber(this.rootStub.prevYtdItem?.earnings[this.key as TEarningsTypes]?.ytd) +
					parseNumber(item.earnings[this.key as TEarningsTypes]?.total)
	}

	/** Calculates ytd total for each paystub if oftenPaid is "ANNUAL".
	 * Ytd total equals total.
	 * @returns {string} ytd total
	 */
	getCalculatedAnnuallyYTD() {
		return this.total
	}
}
