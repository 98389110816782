import React, {FC} from 'react'

import cn from 'classnames/bind'
import Link from 'next/link'
import {useRouter} from 'next/router'

import {IMenuItem} from 'src/types'

import s from './MenuFooterPrivacy.module.scss'
const cx = cn.bind(s)

interface IMenuFooterPrivacy {
	menu: IMenuItem[]
	className?: string
}

const MenuFooterPrivacy: FC<IMenuFooterPrivacy> = ({menu, className}) => {
	const router = useRouter()
	return (
		<nav className={cx('nav-bottom', className)}>
			<ul className={cx('menu-bottom')} data-testid="footer-menu">
				{menu.map((item) => (
					<li className={cx('menu-bottom__item')} key={item.id}>
						<Link
							href={item.slug}
							className={cx('menu-bottom__link', {
								'menu-bottom__link--active': router.pathname === item.slug,
							})}>
							{item.title}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	)
}

MenuFooterPrivacy.displayName = 'MenuFooterPrivacy'

export default MenuFooterPrivacy
