import React, {FC} from 'react'

import cn from 'classnames/bind'

import s from './ProgressBar.module.scss'
const cx = cn.bind(s)

interface IProgressBarContainerProps {
	className?: string
	animationDuration: number
	isFinished: boolean
}

const ProgressBarContainer: FC<IProgressBarContainerProps> = ({
	className,
	animationDuration,
	isFinished,
	children,
}) => {
	return (
		<div
			className={cx('container', className)}
			style={{
				opacity: isFinished ? 0 : 1,
				transition: `opacity ${animationDuration}ms linear`,
			}}>
			{children}
		</div>
	)
}

ProgressBarContainer.displayName = 'ProgressBarContainer'

export default ProgressBarContainer
