import {createContext, ReactNode, useContext, useEffect} from 'react'

import {toJS} from 'mobx'
import {configurePersistable} from 'mobx-persist-store'
import {enableStaticRendering} from 'mobx-react-lite'

import {RootStore} from 'src/models/Root.store'

import {isBrowser} from '..'

enableStaticRendering(typeof window === 'undefined')

const RootStoreContext = createContext<RootStore>(null as unknown as RootStore)
let store: RootStore

configurePersistable({
	storage: typeof window !== 'undefined' ? window?.localStorage : undefined,
})

function initializeStore(): RootStore {
	const _store = store ?? new RootStore()

	// For server side rendering always create a new store
	if (typeof window === 'undefined') return _store

	// Create the store once in the client
	if (!store) store = _store

	return _store
}

export function useRootStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context
}

export function usePayStubStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('usePayStubStore must be used within RootStoreProvider')
	}

	return context?.paystub
}

export function useForm1099Store() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useForm1099Store must be used within RootStoreProvider')
	}

	return context?.form1099
}

export function useW2Store() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useW2Store must be used within RootStoreProvider')
	}

	return context?.w2
}

export function useInvoiceStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useInvoiceStore must be used within RootStoreProvider')
	}

	return context?.invoice
}

export function useAuthStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useAuthStore must be used within RootStoreProvider')
	}

	return context?.auth
}

export function useDraftStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context?.draft
}

export function usePaymentStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('usePaymentStore must be used within RootStoreProvider')
	}

	return context?.payment
}

export function useInvoiceHistoryStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context?.history.invoice
}

export function useForm1099HistoryStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context?.history['1099']
}

export function usePaystubHistoryStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context?.history.paystub
}

export function useHistoryStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context?.history
}

export function useW2HistoryStore() {
	const context = useContext(RootStoreContext)
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context?.history.w2
}

export function RootStoreProvider({children}: {children: ReactNode}) {
	const store = initializeStore()

	useEffect(() => {
		if (isBrowser && process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			;(window as any).store = store
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			;(window as any).toJS = toJS
		}
	}, [store])

	return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>
}

export default RootStoreContext
