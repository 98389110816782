import {FC, useCallback, useState} from 'react'

import cn from 'classnames/bind'
import dynamic from 'next/dynamic'

import MenuPrimary from 'components/blocks/MenuPrimary'
import MenuStub from 'components/blocks/MenuStub'
import Logo from 'components/elements/Logo'
import IconDots from 'src/assets/icons/dots.svg'
import {Col, Container, Row} from 'src/components/elements/grid'
import {IMenuItem} from 'src/types'
import {useWindowSize} from 'src/utils/hooks/useWindowSize'

import s from './Header.module.scss'

const NavigationMobileDrawer = dynamic(() => import('src/components/blocks/NavigationMobileDrawer'))
const cx = cn.bind(s)

interface IHeaderProps {
	hasMenuStub?: boolean
	hasLinks?: boolean
	className?: string
}

const menuDesktop: IMenuItem[] = [
	{id: 1, slug: '/', title: 'Home'},
	// {id: 2, slug: '/reviews', title: 'Reviews'},
	{id: 3, slug: '/pricing', title: 'Pricing'},
	{id: 4, slug: '/faq', title: 'FAQ'},
	{id: 5, slug: '/blog', title: 'Blog'},
]

export const menuStubItems: IMenuItem[] = [
	{
		id: 1,
		title: 'Paystub',
		slug: '/make-your-paystub',
	},
	{
		id: 2,
		title: 'Invoice',
		slug: '/invoice-generator',
	},
	{
		id: 3,
		title: 'W-2 form',
		slug: '/make-your-w2',
	},
	{
		id: 4,
		title: '1099',
		slug: '/make-your-1099',
	},
]

const Header: FC<IHeaderProps> = ({hasMenuStub, hasLinks, className}) => {
	const {width} = useWindowSize()
	const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
	const [isMobileDrawerDirty, setIsMobileDrawerDirty] = useState(false)

	const onToggleNavigation = useCallback(() => {
		setIsMobileNavigationOpen(!isMobileNavigationOpen)
		if (!isMobileNavigationOpen) setIsMobileDrawerDirty(true)
	}, [isMobileNavigationOpen])

	return (
		<>
			<header className={cx('header', className)}>
				<Container>
					<Row>
						<Col>
							<div className={cx('header-inner')}>
								<Logo />
								<div className="d-none d-md-block">
									<MenuPrimary menuItems={menuDesktop} hasLinks={hasLinks} />
								</div>
								<div className="d-block d-md-none">
									<button
										type="button"
										onClick={onToggleNavigation}
										aria-label="Toggle menu"
										className={cx('dots-btn')}
										data-testid="dots-btn">
										<IconDots />
									</button>
									{width <= 768 && (
										<NavigationMobileDrawer
											isOpen={isMobileNavigationOpen}
											onClose={() => {
												setIsMobileNavigationOpen(false)
											}}
											isDirty={isMobileDrawerDirty}
											{...{menuDesktop, menuStubItems}}
										/>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</header>
			{hasMenuStub && (
				<div className="d-none d-md-block">
					<MenuStub menuItems={menuStubItems} />
				</div>
			)}
		</>
	)
}

Header.displayName = 'Header'

export default Header
