import {useEffect} from 'react'

import {useRouter} from 'next/router'

import {useRootStore} from '../contexts/RootStore.context'

export default function usePreviousRoute() {
	const router = useRouter()
	const rootStore = useRootStore()

	useEffect(() => {
		const handleRouteChangeComplete = (route: string) => {
			rootStore.setPreviousRoute(route)
		}

		router.events.on('routeChangeStart', handleRouteChangeComplete)

		return () => router.events.off('routeChangeStart', handleRouteChangeComplete)
	}, [])
}
