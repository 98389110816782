import type {ErrorInfo, FC, ReactNode} from 'react'
import {Component, createContext, Fragment, useContext} from 'react'

import * as Sentry from '@sentry/nextjs'

import {assert} from 'src/utils'

export interface IErrorBoundaryState {
	error: Error | null
}

export interface IErrorFallbackProps {
	error: Error
	onReset: () => void
}

export const ErrorBoundaryContext = createContext<IErrorFallbackProps | null>(null)

export function useError(): IErrorFallbackProps {
	const errorBoundaryContext = useContext(ErrorBoundaryContext)

	assert(
		errorBoundaryContext !== null,
		'`useError` must be nested inside an `ErrorBoundaryProvider`.',
	)

	return errorBoundaryContext
}

export interface IErrorBoundaryProps {
	children?: ReactNode
	fallback: FC<IErrorFallbackProps> | JSX.Element
	onError?: (error: Error, info: ErrorInfo) => void
	onReset?: () => void
}

const initialState = {error: null}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
	constructor(props: IErrorBoundaryProps) {
		super(props)

		this.onReset = this.onReset.bind(this)

		this.state = initialState
	}

	static getDerivedStateFromError(error: Error): Partial<IErrorBoundaryState> {
		return {error}
	}

	componentDidCatch(error: Error, info: ErrorInfo): void {
		this.props.onError?.(error, info)
		Sentry.captureException(error)
	}

	onReset(): void {
		this.props.onReset?.()
		this.setState(initialState)
	}

	render(): JSX.Element {
		const {error} = this.state

		if (error !== null) {
			const {fallback: Fallback} = this.props

			const contextValue = {error, onReset: this.onReset}

			return (
				<ErrorBoundaryContext.Provider value={contextValue}>
					{typeof Fallback === 'function' ? <Fallback {...contextValue} /> : Fallback}
				</ErrorBoundaryContext.Provider>
			)
		}

		return <Fragment>{this.props.children}</Fragment>
	}
}

export default ErrorBoundary
