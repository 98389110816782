import {makeAutoObservable, runInAction} from 'mobx'

import {getDayOfYear, parseNumber, getStringValue, getWorkingDays} from 'src/utils'

import PayStubStore from './PayStub.store'

export default class PayStubDeductionsItemStore implements IDeductionsStub {
	rootStub: PayStubStore
	id
	itemId
	name = ''
	key = ''
	isCustom = false
	private _total = '0.00'
	private _ytd = '0.00'

	constructor(
		rootStub: PayStubStore,
		itemId: number,
		name: string,
		key: string,
		total: string,
		id: number,
		isCustom: boolean,
	) {
		makeAutoObservable(this, {rootStub: false}, {deep: true})
		this.rootStub = rootStub
		this.itemId = itemId
		this.name = name
		this.key = key
		this.id = id
		this.total = total
		this.isCustom = isCustom
	}

	/** Gets calculated total when isAutoCalculation is "true".
	 * Otherwise gets private value _total.
	 *
	 * @returns {string} total
	 */
	get total() {
		if (this.rootStub.employmentType === 'contractor') {
			return '0.00'
		}

		return this._total
	}

	/** Sets value for private field _total. **/
	set total(v) {
		this._total = v
	}

	/** Gets calculated ytd when isAutoCalculation is "true", but if employmentType is "contractor" will return "0.00".
	 * Otherwise gets private value _ytd.
	 *
	 * @returns {string} ytd
	 */
	get ytd() {
		if (this.rootStub.employmentType === 'contractor') {
			return '0.00'
		}
		runInAction(() => {
			this.rootStub.isAutoCalculation ? (this._ytd = this.getCalculatedYTDTotal()) : null
		})
		return this.rootStub.isAutoCalculation ? this.getCalculatedYTDTotal() : this._ytd
	}

	/** Sets value for private field _ytd. **/
	set ytd(v) {
		this._ytd = v
	}

	/** Calculates ytd total (for deduction) according to often paid options.
	 * @returns {string} ytd total
	 */
	getCalculatedYTDTotal() {
		switch (this.rootStub.oftenPaid) {
			case 'DAILY':
				return this.getCalculatedDailyYTD()
			case 'WEEKLY':
				return this.getCalculateYTD()
			case 'BI_WEEKLY':
				return this.getCalculateYTD()
			case 'SEMI_MONTHLY':
				return this.getCalculateYTD()
			case 'MONTHLY':
				return this.getCalculatedMonthlyYTD()
			case 'QUARTERLY':
				return this.getCalculatedQuarterlyYTD()
			case 'SEMI_ANNUAL':
				return this.getCalculatedSemiAnnuallyYTD()
			case 'ANNUAL':
				return this.getCalculatedAnnuallyYTD()
			default:
				return '0.00'
		}
	}

	/** Calculates ytd total for each paystub if oftenPaid is "DAILY".
	 * Ytd total equals working days multiply to total for last paystub.
	 * If isPrevYtd is "true" and work days doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedDailyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customDeduction = item.customDeductions.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customDeduction?.total)
					: parseNumber(item.deductions[this.key as TDeductionsTypes]?.total)

				if (index === 0) {
					const fullYear = item.payDate.getFullYear()
					const workingDays = getWorkingDays(new Date(fullYear, 0, 1), new Date(item.payDate))
					this.rootStub.isPrevYtd && workingDays !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(Math.round(workingDays) * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "WEEKLY" or "BI_WEEKLY" or "SEMI_MONTHLY".
	 * Ytd total equals current period multiply to total for last paystub.
	 * If isPrevYtd is "true" and current period doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculateYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customDeduction = item.customDeductions.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customDeduction?.total)
					: parseNumber(item.deductions[this.key as TDeductionsTypes]?.total)

				if (index === 0) {
					const dayOfYear = getDayOfYear(new Date(arr[index].payDate))
					const period = this.rootStub.getPeriod(dayOfYear)

					this.rootStub.isPrevYtd && period !== 0
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(period * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "MONTHLY".
	 * Ytd total equals current month multiply to total for last paystub.
	 * If isPrevYtd is "true" and current month doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedMonthlyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customDeduction = item.customDeductions.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customDeduction?.total)
					: parseNumber(item.deductions[this.key as TDeductionsTypes]?.total)

				if (index === 0) {
					const month = item.payDate.getMonth() + 1
					this.rootStub.isPrevYtd && month !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(month * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "QUARTERLY".
	 * Ytd total equals current period multiply to total for last paystub.
	 * If isPrevYtd is "true" and current period doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedQuarterlyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customDeduction = item.customDeductions.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customDeduction?.total)
					: parseNumber(item.deductions[this.key as TDeductionsTypes]?.total)
				const period = this.rootStub.getQuarterlyPeriod(new Date(arr[index].payDate))

				if (index === 0) {
					this.rootStub.isPrevYtd && period !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(period * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(period * total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total for each paystub if oftenPaid is "SEMI_ANNUAL".
	 * Ytd total equals current period multiply to total for last paystub.
	 * If isPrevYtd is "true" and current period doesn't equal "1"" the ytd total equals prev ytd plus total for last paystub.
	 * Ytd total equals previous ytd plus total for each subsequent one paystub.
	 * @returns {string} ytd total
	 */
	getCalculatedSemiAnnuallyYTD() {
		const totalsYtd: Array<number> = []
		this.rootStub.items
			.slice()
			.reverse()
			.forEach((item, index, arr) => {
				const customDeduction = item.customDeductions.find((item) => item.id === this.id)
				const total = this.isCustom
					? parseNumber(customDeduction?.total)
					: parseNumber(item.deductions[this.key as TDeductionsTypes]?.total)
				const period = this.rootStub.getSemiAnnuallyPeriod(new Date(arr[index].payDate))

				if (index === 0) {
					this.rootStub.isPrevYtd && period !== 1
						? totalsYtd.push(this.calculateWithPrevYtd(item))
						: totalsYtd.push(period * total)
				} else {
					item.payDate.getFullYear() !== arr[index - 1].payDate.getFullYear()
						? totalsYtd.push(period * total)
						: totalsYtd.push(totalsYtd[index - 1] + total)
				}
			})

		const YTD = totalsYtd.slice().reverse()
		return getStringValue(YTD[this.itemId])
	}

	/** Calculates ytd total if isPrevYtd is "true".
	 * @returns {string} ytd total
	 */
	calculateWithPrevYtd(item: IPayStubItem) {
		const customPrevDeduction = this.rootStub.prevYtdItem?.customDeductions.find(
			(item) => item.id === this.id,
		)
		const customDeduction = item.customDeductions.find((item) => item.id === this.id)

		return this.isCustom
			? parseNumber(customPrevDeduction?.ytd) + parseNumber(customDeduction?.total)
			: parseNumber(this.rootStub.prevYtdItem?.deductions[this.key as TDeductionsTypes]?.ytd) +
					parseNumber(item.deductions[this.key as TDeductionsTypes]?.total)
	}

	/** Calculates ytd total for each paystub if oftenPaid is "ANNUAL".
	 * Ytd total equals total.
	 * @returns {string} ytd total
	 */
	getCalculatedAnnuallyYTD() {
		return this.total
	}
}
