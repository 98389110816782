import axios from 'axios'
import {Timestamp} from 'firebase-admin/firestore'
import {NextApiRequest} from 'next'
import {parseCookies} from 'nookies'
import {ToWords} from 'to-words'
import {ObjectSchema} from 'yup'

import {TOftenPaid} from 'models/PayStub/PayStub.configs'
import {UnprocessableException} from 'src/backend/utils/exceptions'
import {IS_VERCEL_PROD} from 'src/configs/constants'

export const isBrowser = typeof window !== 'undefined'
export const IS_PROD = process.env.NODE_ENV === 'production'

/**
 * This function parses a string value and returns it in a typed format.
 * The value can be a boolean, number or string.
 * If the value is a boolean, it will return a boolean, if it's a number, it will return a number, and if it's a string, it will return a string.
 * @param value {string} The value to parse.
 * @returns {string | number | boolean} The value in a typed format.
 */
export const getTypedInputValue = (value: string) => {
	const isBool = value === 'true' || value === 'false'
	const isNumber = isNaN(parseFloat(value)) === false && isNaN(Number(value)) === false

	let typedValue: string | number | boolean = value
	if (isBool) typedValue = value === 'true'
	if (isNumber) typedValue = Number(value)

	return typedValue
}

/**
 * Convert a date to a string using the specified locales and options.
 *
 * @param {Date | Timestamp | string} date - The date to convert. Defaults to the current date.
 * @param {object} dateOptions - The locales and options to use when converting the date.
 * @param {string} dateOptions.locales - The locales to use when converting the date.
 * @param {Intl.DateTimeFormatOptions} dateOptions.options - The options to use when converting the date.
 * @returns {string} The formatted date.
 */

export function getFormattedDate(
	date: Date | Timestamp | string = new Date(),
	dateOptions: {locales: string; options: Intl.DateTimeFormatOptions} = {
		locales: 'en-US',
		options: {month: 'long', day: '2-digit', year: 'numeric'},
	},
) {
	let dateInstance = date
	if (typeof date === 'string') {
		dateInstance = new Date(date)
	}
	if (typeof date === 'object' && 'toDate' in date) {
		dateInstance = date.toDate()
	}
	return (dateInstance as Date).toLocaleDateString(dateOptions.locales, dateOptions.options)
}

/**
 * Checks if a date is today's date.
 * @param date The date to check.
 * @returns True if the date is today's date, false otherwise.
 */
export const isToday = (date: Date | string) => {
	const dateToCompare = typeof date === 'string' ? new Date(date) : date
	const today = new Date()
	return dateToCompare.toISOString().split('T')[0] === today.toISOString().split('T')[0]
}

/**
 * Returns true if the date is today or later, otherwise returns false.
 *
 * @param date The date to compare to today.
 */
export const isTodayOrLater = (date: string | Date): boolean => {
	const dateToCompare = new Date(date)
	const today = new Date()

	return dateToCompare.getTime() > today.getTime() || isToday(dateToCompare)
}

/**
 * This function checks if a value is numeric.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNumeric(str: any): boolean {
	return !isNaN(str) && !isNaN(parseFloat(str))
}

/** This function generates a random number string of a given length
The default length is 4 */
export function generateNumberString(length = 4): string {
	return Math.random()
		.toString()
		.substring(2, 2 + length)
}

export function generateUniqueId(): number {
	return Math.floor(Math.random() * 1000000)
}

/**
 * Capitalize the first letter of the string
 * @param str - The string to capitalize
 * @returns The capitalized string
 */
export function capitalize(str: string) {
	if (!str || typeof str !== 'string') {
		return String(str || '') || ''
	}
	return str[0].toUpperCase() + str.slice(1)
}

/**
 * Converts a camelCase string into a case provided by params.
 * @param camelCaseStr The string to convert to lowercase with spaces.
 * @param caseType The case type to convert the camelCase string to.
 * @returns The camelCase string converted to the specified case type.
 */
export function camelCaseToString(
	camelCaseStr: string,
	caseType: 'lower' | 'upper' | 'upperFirst' = 'lower',
): string {
	const result = camelCaseStr.replace(/([A-Z])/g, ' $1')
	switch (caseType) {
		case 'lower':
			return result.toLowerCase()
		case 'upper':
			return result.toUpperCase()
		case 'upperFirst':
			return result.charAt(0).toUpperCase() + result.slice(1)
		default:
			return ''
	}
}

export function assert(value: boolean, message?: string): asserts value
export function assert<T>(value: T | null | undefined, message?: string): asserts value is T
export function assert(value: unknown, message?: string): void {
	if (value === false || value == null) {
		if (process.env['NODE_ENV'] !== 'production') {
			throw new Error(message || 'Assertion failed')
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TReplacer = (this: any, key: string, value: any) => any
/**
 * Uses to stringify circular Mobx store
 * @example JSON.stringify('some JSON', getCircularReplacer())
 */
export const getCircularReplacer = (): TReplacer => {
	const seen = new WeakSet()
	return (_, value: string) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return
			}
			seen.add(value)
		}
		return value
	}
}

/**
 * Returns a new array with all the values from the first array that are not
 * present in the second array or string.
 *
 * @param arr The array to filter.
 * @param valuesToFilter The values to filter out.
 * @returns A new array with the filtered values.
 */
export function getFilteredArray(arr: string[], valuesToFilter: string | string[]) {
	if (Array.isArray(valuesToFilter)) {
		return arr.filter((i) => valuesToFilter.indexOf(i) === -1)
	}

	return arr.filter((i) => i !== valuesToFilter)
}

export function getWestPaymentValue(value: number | string | null | undefined): string | null {
	if (!value) return null
	const isNumber =
		typeof value === 'number' ||
		(isNaN(parseFloat(value)) !== true && isNaN(Number(value)) !== true)

	if (!isNumber) return value

	return parseFloat(value + '').toLocaleString('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
}

/**
 * This function returns a string representation of the input value, or '0.00' if the input value is null or undefined.
 * @param value The value to convert to a string.
 * @returns A string representation of the input value, or '0.00' if the input value is null or undefined.
 */
export function getStringValue(value: number | string | null | undefined): string {
	return getWestPaymentValue(value) === null ? '0.00' : String(getWestPaymentValue(value))
}

/**
 * Parses a string or number value, and returns a number or string value.
 * If the input value is null, undefined, or an empty string, then 0 is returned.
 * If the input value is a number, then that number is returned.
 * If the input value is a string, then it is parsed into a number.
 * If the input value is a string and asString is true, then the string is returned without being parsed.
 * @param {string | number | null | undefined} value - The input value to parse.
 * @param {boolean} asString - Whether or not to return the value as a string.
 * @returns {number | string} - The parsed number or string value.
 */
export function parseNumber(value: string | number | null | undefined, asString: true): string
export function parseNumber(value: string | number | null | undefined): number
export function parseNumber(
	value: string | number | null | undefined,
	asString = false,
): number | string {
	if (!value) return 0
	if (typeof value === 'number') return asString ? `${value}` : value
	return asString ? value.replace(/,/g, '') : +value.replace(/,/g, '')
}

/**
 * This function will validate a yup schema and return the casted object
 * @param schema - The schema to validate
 * @param input - The input data to validate against
 * @returns {Promise<T>} - Promise with the casted object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateYupSchema = async <T>(schema: ObjectSchema<any>, input: any): Promise<T> => {
	await schema.validate(input, {abortEarly: false})
	return schema.cast(input)
}

export const getStateFillingStatus = (
	maritalStatus: calculator.TFederalFilingStatus,
): calculator.TStateFillingStatus => {
	switch (maritalStatus) {
		case 'HEAD_OF_HOUSEHOLD':
			return 'H'
		case 'MARRIED':
			return 'M'
		case 'SINGLE':
			return 'S'
		default:
			const _exhaustiveCheck: never = maritalStatus
			exhaustiveCheck(_exhaustiveCheck)
	}
}

export const prepareCardBrand = (brand: string) => {
	switch (brand) {
		case 'american-express':
			return 'amex'
		case 'mastercard':
			return 'master'
		case 'discover':
			return 'discover'
		case 'maestro':
			return 'maestro'
		case 'diners-club':
			return 'diners'
		case 'hipercard':
			return 'hipercard'
		case 'elo':
			return 'elo'
		default:
			return 'visa'
	}
}

/**
 * Function to convert a number to words
 * @param {number | string} number
 * @param {string} currency
 * @returns {string}
 */
export function getWordsFromNumber(number: number | string, currency = 'Dollars') {
	if (number.toString().indexOf(',')) number = number.toString().replace(/,/g, '')
	const numberResult = number.toString().split('.')
	const toWords = new ToWords()

	if (isNaN(+numberResult[0])) return 'Incorrect number'
	switch (numberResult.length) {
		case 1:
			return toWords.convert(+numberResult[0].replace(/,/g, ''))
		case 2:
			return `${toWords.convert(
				+numberResult[0].replace(/,/g, ''),
			)} AND ${+numberResult[1]}/100 ${currency}`
		default:
			return 'Incorrect number'
	}
}

export function getOftenPaidNumberValue(oftenPaid: TOftenPaid): number {
	switch (oftenPaid) {
		case 'DAILY':
			return 260
		case 'WEEKLY':
			return 52
		case 'BI_WEEKLY':
			return 26
		case 'SEMI_MONTHLY':
			return 24
		case 'MONTHLY':
			return 12
		case 'QUARTERLY':
			return 4
		case 'SEMI_ANNUAL':
			return 2
		case 'ANNUAL':
			return 1
		default:
			return 0
	}
}

export function getOftenPaidText(oftenPaid: TOftenPaid): string {
	switch (oftenPaid) {
		case 'WEEKLY':
			return 'Weekly'
		case 'BI_WEEKLY':
			return 'Bi-Weekly'
		case 'SEMI_MONTHLY':
			return 'Semi-Monthly'
		case 'MONTHLY':
			return 'Monthly'
		case 'QUARTERLY':
			return 'Quarterly'
		case 'SEMI_ANNUAL':
			return 'Semi-Annually'
		case 'ANNUAL':
			return 'Annul'
		default:
			return 'Daily'
	}
}

/**
 * Generates a random string of a given length.
 * @param length The length of the string to generate.
 * @param characters The characters to use in the string.
 * @returns The generated string.
 */
export function generateRandomString(length = 10, characters = '0123456789'): string {
	const charactersLength = characters.length
	let randomString = ''
	for (let i = 0; i < length; i++) {
		randomString += characters[Math.floor(Math.random() * charactersLength)]
	}
	return randomString
}

/**
 * This function returns the day of the year for a given date.
 *
 * @param currentDate The date to get the day of the year from
 * @returns The day of the year for the given date
 */
export function getDayOfYear(currentDate: Date): number {
	const startDate = new Date(currentDate.getFullYear(), 0, 0)
	return Math.floor((currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000))
}

/** This function returns the number of days in a given year.
 * The function takes in a Date object, and returns either 366 or 365.
 * The Date object is used to get the time, which is used to determine whether the year is a leap year. */
export function getDaysInYear(year: Date): number {
	const isLeapYear =
		year.getFullYear() % 4 === 0 &&
		(year.getFullYear() % 100 !== 0 || year.getFullYear() % 400 === 0)
	return isLeapYear ? 366 : 365
}

/**  This function returns the amount of working days between two dates.
 * The function is based on the fact that the week starts on Sunday and ends on Saturday.
 * The function does not take into account holidays.
 * @returns  the amount of working days between the two dates. */
export function getWorkingDays(startDate: Date, endDate: Date): number {
	const start = new Date(startDate.getTime())
	const end = new Date(endDate.getTime())
	let count = 0

	while (start <= end) {
		if (start.getDay() !== 0 && start.getDay() !== 6) {
			// Exclude weekends
			count++
		}
		start.setDate(start.getDate() + 1) // Move to the next day
	}

	return count === 0 ? 1 : count
}

/** Given a date, returns true if it is a Saturday or Sunday, false otherwise. */
export function isWeekend(date: Date) {
	return date.getDay() % 6 === 0
}

/** This function gets the work day after a weekend.
 * The function takes a date as a parameter.
 * The function checks if the date is on a weekend.
 * If the date is on a weekend, the function calls itself with the date of the day before.
 * If the date is not on a weekend, the function returns the date. */
export function getWorkDayBeforeWeekend(date: Date): Date {
	if (isWeekend(date)) {
		return getWorkDayBeforeWeekend(new Date(date.setDate(date.getDate() - parseInt('1'))))
	} else {
		return date
	}
}

export const addDay = (date: Date, amount: number) => {
	const newDate = new Date(date.getTime())
	newDate.setDate(date.getDate() + amount)
	newDate.setHours(date.getHours()) // Keep the same hour value as the original date
	return newDate
}

export const addMonth = (date: Date, amount: number) =>
	new Date(date.setMonth(date.getMonth() + amount))

export const onScrollToTop = () => {
	// Fix for Safari
	setTimeout(
		() =>
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			}),
		0,
	)
}

/**
 * Converts a Date, String, or Timestamp to a Date.
 * @param value The value to convert to a Date.
 * @returns The value as a Date object.
 */
export const toDate = (value?: Timestamp | Date | string) => {
	if (!value) return value
	if (typeof value === 'string') {
		return new Date(value)
	}
	if (typeof value === 'object' && 'toDate' in value) {
		return value.toDate()
	}
	return value
}

/**
 * Returns the year of a Date object or a date string
 * @param {Date | string} value - A date object or a date string
 * @returns {number} The year of the date
 */
export const getFullYear = (value?: Date | string) => {
	return typeof value === 'object' ? value.getFullYear() : value
}

/** Returns a date object for the next day. */
export const getTomorrowDate = () => {
	const tomorrow = new Date()
	tomorrow.setDate(tomorrow.getDate() + 1)
	return tomorrow
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

/**
 * Checks if the value is a valid ISO date string.
 * @param value The value to check.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIsoDateString(value: any): boolean {
	return typeof value === 'string' && value.trim().length > 0 && isoDateFormat.test(value)
}

/**
 * This function takes an object body and converts any ISO date strings to Dates.
 * @param body The object to handle
 * @returns The modified JSON body
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleDates(body: any): any {
	if (body === null || body === undefined || typeof body !== 'object') return body

	if (Array.isArray(body)) {
		return body.map((value) => {
			if (isIsoDateString(value)) return new Date(value)
			else if (typeof value === 'object') return handleDates(value)
			else return value
		})
	}

	for (const key of Object.keys(body)) {
		const value = body[key]
		if (isIsoDateString(value)) body[key] = new Date(value)
		else if (typeof value === 'object') handleDates(value)
	}
	return body
}

/**
 * Returns an array of 10 years, starting with the current year and ending with the year 10 years ago.
 * @param {number} currentYear - The current year.
 * @returns {array} An array of 10 years, starting with the current year and ending with the year 10 years ago.
 */
export const getFiveYears = (currentYear: number) => {
	return Array(5)
		.fill(0)
		.map((_, i) => {
			return {value: (currentYear - i).toString(), name: (currentYear - i).toString()}
		})
}

export function exhaustiveCheck(param: never, message = 'Should not reach here'): never {
	throw new Error(`${message}. Parameter came here ${param}`)
}

export function getTrafficAttributes(req: NextApiRequest) {
	const cookies = parseCookies({req})
	const attrs = [
		'utm_source',
		'utm_campaign',
		'uid',
		'pi_clickid',
		'reqid',
		'cid',
		'afid',
		'device_category',
		'utm_content',
		'utm_term',
		'utm_medium',
	] as const
	type TKeys = typeof attrs[number]
	const parsed = Object.fromEntries(attrs.map((attr) => [attr, cookies[attr] || null])) as {
		[K in TKeys]?: string | null
	}

	const userAgent = req.headers['user-agent']
	let deviceCategory = null
	if (userAgent) deviceCategory = isMobile(userAgent) ? 'mobile' : 'desktop'

	return {
		...parsed,
		device_category: parsed.device_category || deviceCategory,
	}
}

function isMobile(userAgent: string) {
	let isMobileValue = false

	if (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)~~xda|xiino/i.test(
			userAgent,
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			userAgent.substr(0, 4),
		)
	)
		isMobileValue = true

	return isMobileValue
}

/** This function pushes an event to the GTM data layer. If the code is running
 * in a development environment, then the event is not pushed. */
export function pushGTMLayer(event: IGTMEvent) {
	if (typeof window !== 'undefined' && window.dataLayer?.push) {
		if (IS_PROD) {
			return window.dataLayer.push(event)
		}
	}
	return null
}

export const posthogTrack = (
	eventName: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	eventProperties?: Record<string, any> | null,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	eventOptions?: Record<string, any>,
) => {
	if (window.posthog) {
		const cookies = parseCookies()
		window.posthog.capture(
			eventName,
			{
				referrer: document.referrer,
				afid: cookies.afid,
				...eventProperties,
			},
			eventOptions,
		)
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const klaviyoTrack = (event: string, properties: Record<string, any>) => {
	if (!isBrowser || !IS_VERCEL_PROD) return undefined
	window._learnq?.push([
		'track',
		event,
		{referrer: document.referrer, href: location.href, ...properties},
	])
}

interface IIdentify {
	email: string
	firstName?: string
	lastName?: string
	phoneNumber?: string
	has_active_payment_subscription: 'Y' | 'N'
	payment_subscription_type: TPaymentPlansKeys | undefined
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const klaviyoIdentify = (properties: IIdentify) => {
	if (!isBrowser || !IS_VERCEL_PROD) return undefined

	const {email, firstName, lastName, phoneNumber, ...otherProperties} = properties
	const preparedProperties = {
		$email: email,
		$first_name: firstName,
		$last_name: lastName,
		$phone_number: phoneNumber,
		...otherProperties,
	}

	window._learnq?.push(['identify', preparedProperties])
}

export const validateEmailByMailgun = async (email: string) => {
	if (!process.env.MAILGUN_API_KEY) {
		throw new UnprocessableException('Environment variable MAILGUN_API_KEY is required')
	}

	const mailgunResult = await axios.get(
		`https://api.mailgun.net/v4/address/validate?address=${email}`,
		{
			auth: {
				username: 'api',
				password: process.env.MAILGUN_API_KEY,
			},
		},
	)
	let isValid = false
	const mailgunData = mailgunResult.data
	const {result, risk, is_disposable_address, did_you_mean} = mailgunData
	const isDeliverable = result === 'deliverable'
	const isLowRisk = risk === 'low'
	const isNotDisposable = is_disposable_address === false
	const isSpecificallyThisAddressChecked = !did_you_mean
	if (isDeliverable && isLowRisk && isNotDisposable && isSpecificallyThisAddressChecked) {
		isValid = true
	} else {
		isValid = false
	}
	return isValid
}

/** This function takes a focus keyword and a list of synonyms, combines them and returns a list of keywords.
 *
 * @param focusKeyword The focus keyword.
 * @param focusSynonyms A list of synonyms for the focus keyword.
 *
 * @return An array of keywords.*/
export const getKeywords = (focusKeyword: string, focusSynonyms: string[] = []) => {
	if (!focusKeyword) return []
	return [focusKeyword, ...focusSynonyms]
}

// TODO: research performance of this approach
// I'm pretty sure this is slow. But how much?
// I need somehow measure it
export function proxyDefaultWrapper<T, D extends Record<string, unknown>>(
	initial: T,
	initialDefaults: D,
): T & D {
	const proxyCache = new Map()

	const getHandler = (defaults: any) => ({
		get(target: any, name: string): any {
			if (
				typeof target[name] === 'object' &&
				target[name] !== null &&
				target[name].constructor.name === 'Object'
			) {
				if (proxyCache.has(target[name])) {
					return proxyCache.get(target[name])
				}

				const proxy = new Proxy(target[name] as object, getHandler(defaults[name] || {}))
				proxyCache.set(target[name], proxy)

				return proxy
			}

			return typeof target[name] === 'number'
				? target[name] ?? defaults[name]
				: target[name] || defaults[name]
		},
	})

	return new Proxy(initial, getHandler(initialDefaults)) as T & D
}
