import React, {FC} from 'react'

import cn from 'classnames/bind'
import Link from 'next/link'
import {useRouter} from 'next/router'

import s from './LoggedUserMenu.module.scss'

const cx = cn.bind(s)

interface ILoggedUserMenuProps {
	className?: string
}

const LoggedUserMenu: FC<ILoggedUserMenuProps> = ({className}) => {
	const router = useRouter()

	return (
		<ul className={cx('menu', className)} data-testid="menu-user-logged">
			<li className={cx('menu__item')}>
				<Link
					href="/user/account"
					className={cx('menu__link', {
						'menu__link--active': router.pathname === '/user/account',
					})}>
					My profile
				</Link>
			</li>
			<li className={cx('menu__item')}>
				<Link
					href="/user/history/paystub"
					className={cx('menu__link', {
						'menu__link--active': router.pathname === '/user/history/paystub',
					})}>
					History
				</Link>
			</li>
		</ul>
	)
}

LoggedUserMenu.displayName = 'LoggedUserMenu'

export default LoggedUserMenu
