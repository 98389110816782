import React, {FC, useState, useRef, RefObject, useMemo} from 'react'

import cn from 'classnames/bind'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {CSSTransition} from 'react-transition-group'

import s from './MenuPrimaryItem.module.scss'
const cx = cn.bind(s)

interface IMenuPrimaryItemProps {
	title: string
	href?: string
	dropdown?: JSX.Element
	isActiveLink?: boolean
	className?: string
}

const MenuPrimaryItem: FC<IMenuPrimaryItemProps> = ({
	title,
	href,
	dropdown,
	isActiveLink = false,
	className,
}) => {
	const [isShown, setIsShown] = useState(false)

	const router = useRouter()
	const isActive = useMemo(
		() => router.pathname === href || isActiveLink,
		[href, isActiveLink, router.pathname],
	)
	const navigationRef: RefObject<HTMLDivElement> = useRef(null)

	const canShown = dropdown && isShown
	return (
		<li
			className={cx('item', className)}
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}>
			{href ? (
				<Link
					href={href}
					prefetch={false}
					className={cx('item__link', {
						'item__link--active': isActive,
					})}>
					{title}
				</Link>
			) : (
				<span
					className={cx('item__link', {
						'item__link--active': canShown || isActiveLink,
					})}>
					{title}
				</span>
			)}

			<CSSTransition
				in={canShown}
				nodeRef={navigationRef}
				timeout={200}
				classNames={{
					enter: cx('enter'),
					enterActive: cx('enter-active'),
					exit: cx('exit'),
					exitActive: cx('exit-active'),
				}}
				unmountOnExit
				mountOnEnter>
				<div className={cx('dropdown')} ref={navigationRef} data-testid="dropdown-logged-user">
					<div className={cx('dropdown__inner', {isShown})}>{dropdown}</div>
				</div>
			</CSSTransition>
		</li>
	)
}

MenuPrimaryItem.displayName = 'MenuPrimaryItem'

export default MenuPrimaryItem
