import React, {FC} from 'react'

import cn from 'classnames/bind'

import s from './ProgressBar.module.scss'
const cx = cn.bind(s)

interface IBarProps {
	className?: string
	animationDuration: number
	progress: number
}

const Bar: FC<IBarProps> = ({className, animationDuration, progress}) => {
	return (
		<div
			className={cx('bar', className)}
			style={{
				marginLeft: `${(-1 + progress) * 100}%`,
				transition: `margin-left ${animationDuration}ms linear`,
			}}
		/>
	)
}

Bar.displayName = 'Bar'

export default Bar
