import {action, makeObservable, observable} from 'mobx'

import {getFormattedDate} from 'src/utils'

import HistoryStore, {IHistoryItem} from './History.store'

export default class InvoiceHistory extends HistoryStore<docs.IInvoiceData> {
	protected documentType: TPdfDocumentsType = 'invoice'
	items: Map<string, IHistoryItem<docs.IInvoiceData>> = new Map()

	constructor() {
		super()
		makeObservable(
			this,
			{
				items: observable,
				searchQuery: observable,
				searchRange: observable,
				setSearchQuery: action,
				setSearchRange: action,
			},
			{deep: true},
		)
	}

	get historyItems() {
		const items = []
		for (const [key, value] of this.items.entries()) {
			items.push({
				formName: value.data?.formName || '',
				from: value.data?.from.name || '',
				billTo: value.data?.to.name || '',
				total: value.data?.total.toFixed(2).toString() || '',
				createdAt: getFormattedDate(value.createdAt, {
					locales: 'en-US',
					options: {month: 'numeric', day: 'numeric', year: 'numeric'},
				}),
				preview: `/user/history/invoice/${key}`,
			})
		}
		return items
	}
}
