import axios from 'axios'

const stickyApiClientPublic = axios.create({
	baseURL: `https://${process.env.NEXT_PUBLIC_STICKY_APP_KEY}.sticky.io/api`,
	headers: {
		'Content-Type': 'application/json',
	},
})

export default stickyApiClientPublic
