import {makeAutoObservable} from 'mobx'
import {makePersistable} from 'mobx-persist-store'

import apiService from 'services/api.service'

/** The Store uses to handle form on the create 1099 page. It stores user's data and send it to the DB */
export default class Form1099Store {
	requiredFields = {
		payersName: '',
		payersAddress: '',
		payersCity: '',
		payersState: '',
		payersZip: '',
		recipientsName: '',
		recipientsAddress: '',
		recipientsCity: '',
		recipientsState: '',
		recipientsZip: '',
	}
	fields = {
		year: new Date(),
		income: '',
		corrected: false,
		rents: '',
		royalties: '',
		otherIncome: '',
		federalIncomeTax: '',
		companyEin: '',
		socialSecurity: '',
		fishingBoat: '',
		medicalAndHealthCare: '',
		payerDirectSales: false,
		substitutePayments: '',
		cropInsurance: '',
		grossProceeds: '',
		field11: '',
		section409A: '',
		accountNumber: '',
		fatca: false,
		excessGoldenParachutePayments: '',
		nonQualifiedDeferredCompensation: '',
		stateTaxWithHeldA: '',
		stateTaxWithHeldB: '',
		payersStateNoA: '',
		payersStateNoB: '',
		stateIncomeA: '',
		stateIncomeB: '',
		illegalAgreement: false,
		terms: false,
	}

	constructor() {
		makeAutoObservable(this, {}, {deep: true})
		if (typeof window !== 'undefined') {
			makePersistable(this, {name: 'Form1099Store', properties: ['requiredFields']})
		}
		this.saveDraft = this.saveDraft.bind(this)
	}

	get isFirstStepFilled() {
		return !!this.fields.year && !!this.fields.income
	}

	get isSecondStepFilled() {
		return (
			!!this.requiredFields.payersName &&
			!!this.requiredFields.payersAddress &&
			!!this.requiredFields.payersCity &&
			!!this.requiredFields.payersState &&
			!!this.requiredFields.payersZip &&
			!!this.requiredFields.recipientsName &&
			!!this.requiredFields.recipientsAddress &&
			!!this.requiredFields.recipientsCity &&
			!!this.requiredFields.recipientsState &&
			!!this.requiredFields.recipientsZip
		)
	}

	/** Stores the document 1099 data in the DB */
	async saveDraft(draftId?: string) {
		return apiService.saveDraft<docs.I1099Data>({
			type: '1099',
			draftId,
			documentData: {
				...this.requiredFields,
				...this.fields,
			},
		})
	}
}
