import React, {useEffect, useState} from 'react'

import {useRouter} from 'next/router'

import ProgressBar from 'src/components/elements/ProgressBar'

const RouteProgressBar = () => {
	const router = useRouter()
	const [isLoadingRoute, setIsLoadingRoute] = useState(false)
	useEffect(() => {
		const onStart = () => {
			setIsLoadingRoute(true)
		}
		const onStop = () => {
			setIsLoadingRoute(false)
		}

		router.events.on('routeChangeStart', onStart)
		router.events.on('routeChangeComplete', onStop)
		router.events.on('routeChangeError', onStop)

		return () => {
			router.events.off('routeChangeStart', onStart)
			router.events.off('routeChangeComplete', onStop)
			router.events.off('routeChangeError', onStop)
		}
	}, [router])

	return <ProgressBar isAnimating={isLoadingRoute} />
}

export default RouteProgressBar
