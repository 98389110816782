import React, {ReactNode, MouseEventHandler} from 'react'

import cn from 'classnames/bind'

import IconPlus from 'assets/icons/plus.svg'

import s from './Button.module.scss'
import Spinner from '../Spinner'

const cx = cn.bind(s)

export type TButtonVariant = 'primary' | 'secondary' | 'text' | 'text-simple' | 'brainableStatic'

export interface IButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	variant?: TButtonVariant
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined
	isLoading?: boolean
	/**
	 * Default value is 'responsive'
	 */
	size?: 'responsive' | 'big' | 'small'
	preContent?: ReactNode
	/**
	 *We can pass as children all react nodes(text, blocks, components, etc.)
	 *
	 * @type {ReactNode}
	 */
	children: ReactNode
	className?: string
	/**
	 *We can render this component as any element (like a, div)
	 *
	 */
	as?: React.ElementType
}
/**
 * Button component that uses on the public pages(landings, blog etc.)
 */
const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
	(
		{
			variant = 'primary',
			size = 'responsive',
			preContent,
			className,
			isLoading,
			children,
			disabled,
			as: El = 'button',
			...props
		},
		ref,
	) => {
		const isSpinnerLight = variant === 'primary'
		const isText = variant === 'text'
		const isSimpleText = variant === 'text-simple'
		return (
			<El
				className={cx('wrapper', [variant, size], {isLoading}, className)}
				disabled={disabled || isLoading}
				{...props}
				ref={ref}>
				{preContent && <span className={s.preContent}>{preContent}</span>}
				{isText && !isSimpleText && (
					<span className={s.preContent}>
						<IconPlus />
					</span>
				)}
				<span className={s.content}>{children}</span>
				{isLoading && <Spinner className={s.spinner} variant={isSpinnerLight ? 'light' : 'dark'} />}
			</El>
		)
	},
)

Button.displayName = 'Button'

export default Button
