import React, {FC} from 'react'

import cn from 'classnames/bind'
import Link from 'next/link'
import {useRouter} from 'next/router'

import s from './ItemStub.module.scss'
const cx = cn.bind(s)

interface IItemStubProps {
	title: string
	slug: string
	className?: string
}

const ItemStub: FC<IItemStubProps> = ({title, slug, className}) => {
	const router = useRouter()
	return (
		<li className={cx('item-stub', className)}>
			<Link
				href={slug}
				prefetch={false}
				className={cx('item-stub__link', {'item-stub__link--active': slug === router.pathname})}>
				{title}
			</Link>
		</li>
	)
}

ItemStub.displayName = 'ItemStub'

export default ItemStub
