import React, {FC} from 'react'

import cn from 'classnames/bind'

import s from './HeadingFooter.module.scss'
const cx = cn.bind(s)

interface IHeadingFooterProps {
	children: string | JSX.Element
	className?: string
}

const HeadingFooter: FC<IHeadingFooterProps> = ({children, className}) => {
	return <div className={cx('heading', className)}>{children}</div>
}

HeadingFooter.displayName = 'HeadingFooter'

export default HeadingFooter
