import {makeAutoObservable} from 'mobx'
import {makePersistable} from 'mobx-persist-store'

/** Contains and handles info about saved on the server draft */
export default class DraftStore {
	type: TPdfDocumentsType | null = null
	draftId = ''
	template?: number = undefined
	theme?: TPdfDocumentsThemes = undefined

	constructor() {
		makeAutoObservable(this, {}, {deep: true})
		makePersistable(this, {name: 'DraftStore', properties: ['type', 'draftId']})
		this.reset = this.reset.bind(this)
	}

	setDraft(
		draftId: string,
		type: TPdfDocumentsType,
		template: number | undefined,
		theme: TPdfDocumentsThemes | undefined,
	) {
		this.draftId = draftId
		this.type = type
		this.template = template
		this.theme = theme
	}

	reset() {
		this.draftId = ''
		this.type = null
		this.template = undefined
		this.theme = undefined
	}
}
