import React, {FC} from 'react'

import cn from 'classnames/bind'

import {Container, Row, Col} from 'src/components/elements/grid'
import ItemStub from 'src/components/elements/ItemStub'
import {IMenuItem} from 'src/types'
import {posthogTrack} from 'src/utils'

import s from './MenuStub.module.scss'
const cx = cn.bind(s)

interface IMenuStubProps {
	menuItems: IMenuItem[]
	className?: string
}

const MenuStub: FC<IMenuStubProps> = ({menuItems, className}) => {
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			className={cx('nav-wrapper', className)}
			onClick={(e) => {
				const target = e.target as HTMLAnchorElement
				if (target.nodeName.toLowerCase() === 'a') {
					posthogTrack('[TOP MENU] Button clicked', {buttonText: target.textContent})
				}
			}}>
			<Container>
				<Row>
					<Col>
						<nav className={cx('nav-stub')}>
							<ul className={cx('menu-stub')}>
								{menuItems.map((item) => (
									<ItemStub
										title={item.title}
										slug={item.slug}
										key={item.id}
										className={cx('menu-stub__item')}
									/>
								))}
							</ul>
						</nav>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

MenuStub.displayName = 'MenuStub'

export default MenuStub
