import {getDownloadURL, ref, uploadString} from 'firebase/storage'
import {makeAutoObservable} from 'mobx'
import {makePersistable} from 'mobx-persist-store'
import {v4} from 'uuid'

import apiService from 'services/api.service'
import {storage} from 'src/lib/firebaseApp'
import {generateNumberString, getFilteredArray, parseNumber} from 'src/utils'

import {
	discountTypeOptions,
	taxTypeOptions,
	TDiscountTypes,
	TTaxTypes,
	TTerms,
} from './Invoice.configs'
import InvoiceItemStore from './InvoiceItem.store'
import {RootStore} from '../Root.store'

const DAY_IN_MS = 1000 * 60 * 60 * 24

/** Uses to handle the Invoice form data */
export default class InvoiceStore {
	root: RootStore
	formName = ''
	logo = ''
	from = {
		name: '',
		email: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		businessNumber: '',
	}
	to = {
		name: '',
		email: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
	}

	number = ''
	date = new Date()
	private _customDue: Date | undefined = new Date()
	terms: TTerms | undefined = 'none'
	taxType: TTaxTypes | undefined = taxTypeOptions.find((i) => i.isDefault)?.value
	taxRate = ''
	discountType: TDiscountTypes | undefined = discountTypeOptions.find((i) => i.isDefault)?.value
	discountRate = ''
	items: InvoiceItemStore[] = []
	notes = ''

	constructor(root: RootStore) {
		makeAutoObservable(this, {root: false}, {deep: true})
		if (typeof window !== 'undefined') {
			makePersistable(this, {
				name: 'InvoiceStore',
				properties: getFilteredArray(Object.keys(this), [
					'items',
					'to',
					'logo',
					'date',
					'_customDue',
				]) as unknown as (keyof this)[],
			})
		}

		this.root = root
		this.items.push(new InvoiceItemStore(root))
		this.formName = 'Invoice'
		this.number = `INV${generateNumberString()}`
		this.saveDraft = this.saveDraft.bind(this)
	}

	setCustomDue(v: Date) {
		this._customDue = v
	}

	/**  The function uploads the logo to the storage bucket.
	 * 1. If the logo is not selected, the function will return an empty string.
	 * 2. We set a constant variable for the expiration date of the logo.
	 * 3. We generate the id for the logo.
	 * 4. We create a reference to the storage bucket.
	 * 5. We upload the logo to the storage bucket.
	 * 6. We get the download link of the logo and return it.
	 */
	async uploadLogo() {
		if (!this.logo) return ''
		const DAY_IN_MS = 1000 * 60 * 60 * 24
		const expiredTimestamp = Date.now() + DAY_IN_MS * 30
		const expiredDate = new Date(expiredTimestamp).toLocaleDateString('en-US').split('/').join('.')

		const id = v4()
		const logoRef = ref(storage, `logos-bucket/${expiredDate}/@invoice-${id}`)
		const res = await uploadString(logoRef, this.logo, 'data_url')
		const src = await getDownloadURL(res.ref)
		return src
	}

	/** Saves the draft with data based on this class
	 * 1. Uploads an image to the server by the uploadImage function.
	 * 2. then creates a new draft by sending a POST request to the server with an object that contains all the data that needs to be saved based on this class. */
	async saveDraft(template: number, draftId?: string, theme?: TPdfDocumentsThemes) {
		const items = this.items.map((item: InvoiceItemStore) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const {root, ...itemData} = item
			const documentItem: IInvoiceItem = {
				...itemData,
				amount: item.amount,
				discountedAmount: item.discountedAmount,
			}
			return documentItem
		})

		const logo = await this.uploadLogo()

		return apiService.saveDraft<docs.IInvoiceData>({
			type: 'invoice',
			draftId,
			template,
			theme,
			documentData: {
				logo,
				formName: this.formName,
				from: this.from,
				to: this.to,
				number: this.number,
				date: this.date,
				terms: this.terms,
				taxType: this.taxType,
				taxRate: this.taxRate,
				subtotal: this.subtotal,
				due: this.due,
				tax: this.tax,
				discount: this.discount,
				total: this.total,
				discountType: this.discountType,
				discountRate: this.discountRate,
				items,
				notes: this.notes,
			},
		})
	}

	get due(): Date | undefined {
		if (!this.date) return undefined
		if (this.terms === 'none' || this.terms === 'on_receipt') {
			return undefined
		}

		if (this.terms === 'custom') {
			return this._customDue
		}

		const termsNumber = Number(this.terms)
		if (isNaN(termsNumber) || termsNumber < 1) return undefined

		return new Date(this.date.getTime() + DAY_IN_MS * termsNumber)
	}

	get subtotal() {
		return this.items.reduce((prev, curr) => {
			return curr.amount + prev
		}, 0)
	}

	get tax() {
		return this.items.reduce((prev, curr) => {
			if (curr.withTax) {
				switch (this.taxType) {
					case 'deducted':
						return prev - (curr.discountedAmount * parseNumber(this.taxRate)) / 100 || 0
					case 'on_total':
						return prev + (curr.discountedAmount * parseNumber(this.taxRate)) / 100 || 0

					default:
						return 0
				}
			}
			return prev
		}, 0)
	}

	get discount() {
		if (this.discountType === 'none') {
			return 0
		}

		let value = (this.subtotal * parseNumber(this.discountRate) || 0) / 100
		if (this.discountType === 'flat_amount') {
			value = parseNumber(this.discountRate) || 0
		}

		return value
	}

	get total() {
		return this.subtotal + this.tax - this.discount
	}
}
