import {init} from 'next-firebase-auth'

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000

const initAuth = () => {
	init({
		debug: false,
		authPageURL: '/auth/login',
		appPageURL: '/',
		loginAPIEndpoint: '/api/login',
		logoutAPIEndpoint: '/api/logout',

		firebaseAdminInitConfig: {
			credential: {
				privateKey: process.env.FB_PRIVATE_KEY
					? process.env.FB_PRIVATE_KEY.replace(/\\n/g, '\n')
					: '',
				clientEmail: process.env.FB_CLIENT_EMAIL,
				projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID || '',
			},
			databaseURL: process.env.NEXT_PUBLIC_FB_DATABASE_URL,
		},

		firebaseClientInitConfig: {
			apiKey: process.env.NEXT_PUBLIC_FB_API_KEY || '',
			authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
			projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
			storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
			messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
			appId: process.env.NEXT_PUBLIC_FB_APP_ID,
			measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID,
		},
		cookies: {
			name: 'paystub',
			keys: [process.env.FB_COOKIE_SECRET_CURR, process.env.FB_COOKIE_SECRET_PREV],
			httpOnly: true,
			maxAge: TWELVE_DAYS_IN_MS,
			overwrite: true,
			path: '/',
			sameSite: 'lax',
			secure: process.env.NEXT_PUBLIC_COOKIE_SECURE === 'true',
			signed: true,
		},
	})
}

export default initAuth
