import apiService from 'services/api.service'

export interface IHistoryItem<T extends docs.TDocumentData = docs.TDocumentData>
	extends Partial<DB.IUserDocument<T>> {}

/**
 * An abstract class that holds the base methods of each history type's stores.
 * @abstract
 */
export default abstract class HistoryStore<T extends docs.TDocumentData> {
	/** Uses in the fetch methods */
	protected abstract documentType: TPdfDocumentsType
	/**
	 * All fetched items to show
	 * @abstract
	 * @type {Map<string, IHistoryItem<T>>}
	 */
	abstract items: Map<string, IHistoryItem<T>>
	/** Uses to determine if they're more items and we can fetch them by the fetchMore method */
	hasMore = true
	/** search query from the search input */
	searchQuery = ''
	/** search range query from the Date input */
	searchRange: {startDate: Date | undefined; endDate: Date | undefined} | undefined = undefined

	/** Fetches all items(filtered and limited) from the DB and updates the items list. */
	public fetchAll = async (): Promise<void> => {
		const limit = 15
		const res = await apiService.getFilteredDocuments<T, Api.profileDocuments.TFilter>(
			this.documentType,
			{
				limit,
				filter: {
					name: this.searchQuery,
					periodStart: this.searchRange?.startDate?.getTime(),
					periodEnd: this.searchRange?.endDate?.getTime(),
				},
			},
		)

		if (res.ok && res.data) {
			const newItems = res.data
			this.items.clear()

			newItems.forEach(({id, ...item}) => {
				this.items.set(id, item)
			})

			this.hasMore = newItems.length === limit
		}
	}

	/**
	 * This function fetches more items from the server and appends them to the list.
	 * It is called when the user scrolls to the bottom of the list.
	 * It only fetches more items if there are items in the list, and if the server
	 * has more items to fetch. */
	public fetchMore = async () => {
		const limit = 10
		const lastId = [...this.items.keys()].pop()
		const res = await apiService.getFilteredDocuments<T, Api.profileDocuments.TFilter>(
			this.documentType,
			{
				from: lastId,
				limit,
				filter: {
					name: this.searchQuery,
					periodStart: this.searchRange?.startDate?.getTime(),
					periodEnd: this.searchRange?.endDate?.getTime(),
				},
			},
		)

		if (res.ok && res.data) {
			const newItems = res.data

			newItems.forEach(({id, ...item}) => {
				this.items.set(id, item)
			})

			this.hasMore = newItems.length === limit
		}
	}

	/** Fetches item by ID. Uses on the history item page */
	public fetchItem = async (id: string) => {
		const res = await apiService.getUserDocumentById<T>(id, {type: this.documentType})
		const body = res.data
		if (body.ok && body.data) {
			const data = body.data

			this.items.set(id, data)
		}
	}

	public setSearchQuery = (query: string) => {
		this.searchQuery = query
	}

	public setSearchRange = (range: {startDate: Date | undefined; endDate: Date | undefined}) => {
		this.searchRange = range
	}
}
