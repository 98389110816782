import {useEffect} from 'react'

import {setCookie} from 'nookies'

import {isSearchEngine} from 'src/utils/isSearchEngine'

const PARAM_NAMES = [
	'afid',
	'gclid',
	'utm_source',
	'utm_medium',
	'utm_campaign',
	'utm_content',
	'utm_term',
	'aic', // ReferralCandy referral ID
]

/** Saves traffic query attributes to cookies when user opens the website */
export const useTrafficParamsSaver = () => {
	useEffect(() => {
		// Do nothing if query is empty
		if (!window.location.search) return

		// Parse query string
		const entries = new URLSearchParams(window.location.search).entries()
		const object = Object.fromEntries(entries)

		// If page doesn't have "afid" (ad campaign id) and user came from search engine, set "afid" to "seo".
		// So that we can analyze the amount of orders made by users who came from search engines.
		if (!object.afid && isSearchEngine(document.referrer)) object.afid = 'seo'

		// Find marketing params
		PARAM_NAMES.forEach((name) => {
			const value = object[name]
			if (value) setCookie(null, name, value)
		})
	}, [])
}
