// Import the functions you need from the SDKs you need
import {getApps, initializeApp} from 'firebase/app'
import {
	browserLocalPersistence,
	browserSessionPersistence,
	getAuth,
	indexedDBLocalPersistence,
	initializeAuth,
} from 'firebase/auth'
import {getStorage} from 'firebase/storage'
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FB_APP_ID,
	measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID,
}

const existingApps = getApps()

// Initialize Firebase
const firebaseApp = existingApps.length === 0 ? initializeApp(firebaseConfig) : undefined

const getMyAuth = () => {
	if (firebaseApp) {
		const auth = initializeAuth(firebaseApp, {
			persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
		})
		return auth
	}
	return getAuth(firebaseApp)
}
export const auth = getMyAuth()
export const storage = getStorage(firebaseApp)

export default firebaseApp
