import {FirebaseError} from 'firebase/app'
import {
	EmailAuthProvider,
	confirmPasswordReset,
	signInWithCustomToken as firebaseSignInWithCustomToken,
	reauthenticateWithCredential,
	sendPasswordResetEmail,
	signOut,
	updatePassword,
} from 'firebase/auth'
import {makeAutoObservable} from 'mobx'
import {makePersistable} from 'mobx-persist-store'

import apiService from 'services/api.service'
import {auth} from 'src/lib/firebaseApp'
import {klaviyoIdentify} from 'src/utils'

export interface IUser extends DB.IUser {
	id: string
	subscription?: DB.IUserSubscription
	isMailing?: boolean
}

export default class AuthStore {
	user: IUser | null = null
	isLoggedIn = false
	isFirebaseLoaded = false
	chatId = ''

	constructor() {
		makeAutoObservable(this, {}, {deep: true})
		makePersistable(this, {name: 'AuthStore', properties: ['user', 'isLoggedIn', 'chatId']})
	}

	private _signInWithCustomToken = async (token: string) => {
		return firebaseSignInWithCustomToken(auth, token)
	}

	get hasActiveSubscription() {
		return !!this.user?.subscription?.isActive
	}

	setChatId(chatId: string) {
		this.chatId = chatId
	}

	signIn = async (body: Api.signIn.IBody) => {
		const res = await apiService.signIn(body)

		if (res.data.ok) {
			const token = res.data.data.customToken
			await this._signInWithCustomToken(token)
		}

		return res
	}

	getFirebaseUser = () => {
		return auth.currentUser
	}

	signUp = async (body: Api.signUp.IBody) => {
		const res = await apiService.signUp(body)

		if (res.data.ok) {
			const token = res.data.data.customToken
			const userCredentials = await this._signInWithCustomToken(token)
			this.user = {
				firstName: body.firstName,
				lastName: body.lastName,
				id: userCredentials.user.uid,
				roles: {guest: true},
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				email: userCredentials.user.email!,
			}
		}

		return res
	}

	setProfile = (fields: Partial<IUser>) => {
		this.user = {...(this.user as IUser), ...fields}
	}

	fetchProfileData = async () => {
		const {user, subscription} = (await apiService.getProfileData()).data.data

		if (user) {
			this.user = {...user, subscription}
			this._identifyAllAnalytics(this.user)
		} else {
			throw new Error('Something went wrong')
		}
	}

	private _identifyAllAnalytics = (user: IUser) => {
		const {email, firstName, lastName, isValidEmail} = user

		if (isValidEmail) {
			klaviyoIdentify({
				email,
				firstName,
				lastName,
				has_active_payment_subscription: user.subscription?.isActive ? 'Y' : 'N',
				payment_subscription_type: user.subscription?.subscriptionType,
			})
		}
	}

	updateProfile = async (body: Api.updateProfile.IBody) => {
		return apiService.updateProfile(body)
	}

	stopSubscription = () => {
		return apiService.stopSubscription()
	}

	signOut = async () => {
		signOut(auth)
		apiService.logout()
	}

	resetPassword = async (email: string) => {
		return sendPasswordResetEmail(auth, email)
	}

	changePassword = async (oldPassword: string, newPassword: string) => {
		const currentUser = auth.currentUser
		if (currentUser && currentUser.email) {
			const credential = EmailAuthProvider.credential(currentUser.email, oldPassword)
			try {
				const {user} = await reauthenticateWithCredential(currentUser, credential)
				await updatePassword(user, newPassword)
				await apiService.notifyPasswordUpdated()
			} catch (e) {
				if ((e as FirebaseError).code === 'auth/wrong-password') {
					throw new Error('Invalid credentials')
				}
				throw new Error('Something went wrong')
			}
		} else {
			throw new Error('User is not loaded yet.')
		}
	}

	createNewPassword = async (password: string, oobCode: string) => {
		await confirmPasswordReset(auth, oobCode as string, password)
	}
}
