import {useEffect, useRef} from 'react'

import {onAuthStateChanged} from 'firebase/auth'

import {auth} from 'src/lib/firebaseApp'

import useAsyncFlow from './useAsyncFlow'
import {useAuthStore} from '../contexts/RootStore.context'

export default function useAuthState() {
	const authStore = useAuthStore()
	const debounceTimer = useRef<ReturnType<typeof setTimeout>>()
	const [fetchProfileDataFlow] = useAsyncFlow<typeof authStore.fetchProfileData>(
		authStore.fetchProfileData,
	)

	useEffect(() => {
		return onAuthStateChanged(auth, async (authUser) => {
			if (authUser) {
				authStore.isLoggedIn = true
				authStore.isFirebaseLoaded = true
				const updateUserData = async () => {
					await fetchProfileDataFlow()()
				}

				if (!debounceTimer.current) {
					updateUserData()
				}

				debounceTimer.current = setTimeout(() => {
					if (debounceTimer.current) {
						clearTimeout(debounceTimer.current)
						debounceTimer.current = undefined
					}
				}, 10)

				return
			}
			authStore.user = null
			authStore.isLoggedIn = false
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return authStore
}
