import axios from 'axios'
import cardValidator from 'card-validator'
import {makeAutoObservable} from 'mobx'
import * as yup from 'yup'

import {paymentFormSchema} from 'components/brainable/CheckoutForm/CheckoutForm'
import apiService from 'services/api.service'
import stickyApiClientPublic from 'src/lib/stickyApiClientPublic'
import {prepareCardBrand} from 'src/utils'

interface ITokenizePayment {
	cardNumber: string
	cvv: string
	expiry: string
	brand: string
}

interface IMakePaymentParams {
	userId: string
	paymentType: TPaymentPlansKeys
	formData: {
		cardNumber: string
		expiration: string
		cvc: string
		zip: string
		agreement: boolean
		cavv?: string
		eci?: string
		xid?: string
		flexcharge_order_id?: string
	}
}
export default class PaymentStore {
	data: yup.TypeOf<typeof paymentFormSchema> | null = null

	constructor() {
		makeAutoObservable(this, {}, {deep: true})
	}

	private _getAuthTokenForTokenizePayment = async () => {
		return (await axios.get<Api.authTokenizeToken.IResponse>('/payments/auth-tokenize-token')).data
	}

	/**
	 * Tokenize payment with user's the data from the form.
	 *
	 *
	 * @private
	 * @param {ITokenizePayment} data
	 * @returns payment token used during creating a order
	 * @memberof PaymentsService
	 */
	private _tokenizePayment = async (data: ITokenizePayment) => {
		const {cardNumber, brand, cvv, expiry} = data
		const authToken = await this._getAuthTokenForTokenizePayment()

		if (!authToken.ok || !authToken.data?.token) {
			return undefined
		}

		return (
			await stickyApiClientPublic.post<Sticky.v2.IPaymentTokenResponse>(
				'v2/tokenize_payment',
				{
					card_number: cardNumber,
					cvv,
					expiry,
					brand,
				},
				{
					headers: {
						Authorization: `Bearer ${authToken.data.token}`,
						'Content-Type': 'application/json',
					},
				},
			)
		).data
	}

	/**
	 * Transform data to format that the sticky.io accept
	 *
	 * @param {IMakePaymentParams} data
	 * @returns {ITokenizePayment} transformed data
	 * @memberof PaymentsService
	 */
	_transformDataToTokenize = (data: IMakePaymentParams): ITokenizePayment => {
		const formData = data.formData
		const creditCardBrand = cardValidator.number(formData.cardNumber).card?.type
		const month = formData.expiration.slice(0, 2)
		const year = formData.expiration.slice(3, 5)

		if (!creditCardBrand) {
			throw new Error('Credit card brand not found')
		}

		const transformedData: ITokenizePayment = {
			...formData,
			brand: prepareCardBrand(creditCardBrand),
			cardNumber: formData.cardNumber.replaceAll(' ', ''),
			expiry: `${month}-20${year}`,
			cvv: String(formData.cvc),
		}

		return transformedData
	}

	/**
	 *  Tokenize payment with the data from form and create order by our API
	 *
	 * @param {IMakePaymentParams} data
	 * @returns data about the created order
	 * @memberof PaymentsService
	 */
	makePayment = async (data: IMakePaymentParams) => {
		const tokenResult = await this._tokenizePayment(this._transformDataToTokenize(data))
		const paymentToken = tokenResult?.data.token

		if (!paymentToken) {
			throw new Error('Payment token not found')
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const {cardNumber, expiration, cvc, ...insensitiveFormData} = data.formData

		const createOrderData = {
			...data,
			formData: insensitiveFormData,
		}

		const orderRes = await apiService.createOrder({...createOrderData, paymentToken})

		return orderRes.data
	}
}
