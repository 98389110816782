import {FC} from 'react'

import cn from 'classnames/bind'
import dynamic from 'next/dynamic'

import Header from 'components/blocks/Header'
import Footer from 'src/components/blocks/Footer'
import experiments from 'src/utils/experiments'
import {useExperiment} from 'src/utils/hooks/useExperiment'
import usePreviousRoute from 'src/utils/hooks/usePreviousRoute'

import s from './LayoutDefault.module.scss'

const cx = cn.bind(s)

const Chat = dynamic(() => import('src/components/blocks/Chat'), {ssr: false})

interface ILayoutDefaultProps {
	hasMenuStub?: boolean
	hasMenuLinks?: boolean
	className?: string
}

const LayoutDefault: FC<ILayoutDefaultProps> = ({
	hasMenuStub = true,
	hasMenuLinks = true,
	className,
	children,
}) => {
	const featureFlag = useExperiment(experiments.chatbot.id)

	usePreviousRoute()

	return (
		<div className={cx('wrapper', className)}>
			<Header hasMenuStub={hasMenuStub} hasLinks={hasMenuLinks} />
			<main className={cx('main', '_main')}>{children}</main>
			<Footer />
			<div id="portals"></div>
			{featureFlag !== 'without-chatbot' && <Chat />}
		</div>
	)
}

LayoutDefault.displayName = 'LayoutDefault'

export default LayoutDefault
