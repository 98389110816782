const getMobileDetect = (userAgent: string) => {
	const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i))
	const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
	const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i))
	const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i))
	const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i))

	const isMobile = (): boolean => Boolean(isAndroid() || isIos() || isOpera() || isWindows())
	const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR())
	return {
		isMobile,
		isDesktop,
		isAndroid,
		isIos,
		isSSR,
	}
}

/** React hook to detect the device type. This hook is able to detect mobile, desktop, android or iOS device.
 * @returns functions to detect the device type
 */
const useMobileDetect = () => {
	const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
	return getMobileDetect(userAgent)
}

export default useMobileDetect
