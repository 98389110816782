import React, {FC} from 'react'

import cn from 'classnames/bind'
import Link from 'next/link'

import LogoDefault from 'src/assets/logoDefault.svg'
import LogoTransparent from 'src/assets/logoTransparent.svg'

import s from './Logo.module.scss'
const cx = cn.bind(s)

interface ILogoProps {
	type?: 'default' | 'transparent'
	className?: string
}

const Logo: FC<ILogoProps> = ({type = 'default', className}) => {
	return (
		<div className={cx('wrapper', className)}>
			<Link href="/" data-testid="footer-logo" aria-label="Go to the home page">
				{type === 'default' && <LogoDefault className={cx('logo')} />}
				{type === 'transparent' && <LogoTransparent className={cx('logo')} />}
			</Link>
		</div>
	)
}

Logo.displayName = 'Logo'

export default Logo
