import React, {FC} from 'react'

import cn from 'classnames/bind'

import s from './Spinner.module.scss'
const cx = cn.bind(s)

interface ISpinnerProps {
	className?: string
	variant?: 'light' | 'dark'
}

const Spinner: FC<ISpinnerProps> = ({className, variant}) => {
	const isLight = variant === 'light'
	const fill = isLight ? 'var(--appWhite, #fff)' : 'var(--appBlack, #1f2d44)'
	return (
		<div className={cx('wrapper', className)} data-testid="spinner">
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				width="24px"
				height="24px"
				viewBox="0 0 24 30"
				xmlSpace="preserve">
				<rect x="0" y="10" width="4" height="10" fill={fill} opacity="0.2">
					<animate
						attributeName="opacity"
						attributeType="XML"
						values="0.2; 1; .2"
						begin="0s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="height"
						attributeType="XML"
						values="10; 20; 10"
						begin="0s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="y"
						attributeType="XML"
						values="10; 5; 10"
						begin="0s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
				</rect>
				<rect x="8" y="10" width="4" height="10" fill={fill} opacity="0.2">
					<animate
						attributeName="opacity"
						attributeType="XML"
						values="0.2; 1; .2"
						begin="0.15s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="height"
						attributeType="XML"
						values="10; 20; 10"
						begin="0.15s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="y"
						attributeType="XML"
						values="10; 5; 10"
						begin="0.15s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
				</rect>
				<rect x="16" y="10" width="4" height="10" fill={fill} opacity="0.2">
					<animate
						attributeName="opacity"
						attributeType="XML"
						values="0.2; 1; .2"
						begin="0.3s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="height"
						attributeType="XML"
						values="10; 20; 10"
						begin="0.3s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
					<animate
						attributeName="y"
						attributeType="XML"
						values="10; 5; 10"
						begin="0.3s"
						dur="0.6s"
						repeatCount="indefinite"
					/>
				</rect>
			</svg>
		</div>
	)
}

Spinner.displayName = 'Spinner'

export default Spinner
