import {action, makeObservable, observable} from 'mobx'

import {getFormattedDate} from 'src/utils'

import HistoryStore, {IHistoryItem} from './History.store'

export default class W2HistoryStore extends HistoryStore<docs.IW2Data> {
	protected documentType: TPdfDocumentsType = 'w2'
	items: Map<string, IHistoryItem<docs.IW2Data>> = new Map()

	constructor() {
		super()
		makeObservable(
			this,
			{
				items: observable,
				searchQuery: observable,
				searchRange: observable,
				setSearchQuery: action,
				setSearchRange: action,
			},
			{deep: true},
		)
	}

	get historyItems() {
		const items = []

		for (const [key, value] of this.items.entries()) {
			items.push({
				companyName: value.data?.companyName || '',
				employeeName: value.data?.employeeName || '',
				year: value.data?.year.getFullYear().toString() || '',
				wages: value.data?.income || '',
				createdAt: getFormattedDate(value.createdAt, {
					locales: 'en-US',
					options: {month: 'numeric', day: 'numeric', year: 'numeric'},
				}),
				preview: `/user/history/w2/${key}`,
			})
		}
		return items
	}
}
