import {addBreadcrumb, captureException} from '@sentry/nextjs'
import {AxiosError} from 'axios'
import {makeAutoObservable, runInAction} from 'mobx'

import apiService from 'services/api.service'
import {
	posthogTrack,
	generateUniqueId,
	parseNumber,
	getStringValue,
	getStateFillingStatus,
} from 'src/utils'

import {earnings, deductions} from './PayStub.configs'
import PayStubStore from './PayStub.store'
import PayStubDeductionsItemStore from './PayStubDeductionsItem.store'
import PayStubEarningsItemStore from './PayStubEarningsItem.store'

export default class PayStubItemStore implements IPayStubItem {
	rootStub: PayStubStore
	itemId: number
	earnings: {
		[key in TEarningsTypes]: PayStubEarningsItemStore | undefined
	} = {
		contractor: undefined,
		salary: undefined,
		regular: undefined,
		overtime: undefined,
		holiday: undefined,
		vacation: undefined,
		bonus: undefined,
		float: undefined,
		tips: undefined,
	}
	customEarnings: PayStubEarningsItemStore[] = []
	deductions: {
		[key in TDeductionsTypes]: IDeductionsStub | undefined
	} = {
		medicare: undefined,
		fica: undefined,
		federal: undefined,
		state: undefined,
		sdi: undefined,
		sui: undefined,
		wc: undefined,
		fli: undefined,
		trans: undefined,
		wst: undefined,
	}
	customDeductions: IDeductionsStub[] = []
	private _grossPayTotal = '0.00'
	private _grossPayYTDTotal = '0.00'
	private _deductionTotal = '0.00'
	private _deductionYTDTotal = '0.00'
	private _netpayTotal = '0.00'
	private _netpayYTDTotal = '0.00'
	payPeriod = {from: new Date(), to: new Date()}
	payDate = new Date()

	constructor(rootStub: PayStubStore, index: number) {
		makeAutoObservable(this, {rootStub: false}, {deep: true})
		this.rootStub = rootStub
		this.itemId = index
		this.payDate = new Date(new Date().setHours(0, 0, 0, 0))
		this.payPeriod.to = new Date(this.payDate)
		this.payPeriod.from = this.rootStub.getDayByOftenPaid(new Date(this.payDate), false)

		/** Create default list of earnings. **/
		earnings.forEach(({key, name}) => {
			const uniqueId = generateUniqueId()
			this.earnings[key] = new PayStubEarningsItemStore(
				this.rootStub,
				this,
				this.itemId,
				name,
				key,
				uniqueId,
				false,
			)
		})

		/** Create default list of deductions. **/
		deductions.forEach(({key, name}) => {
			this.deductions[key] = new PayStubDeductionsItemStore(
				this.rootStub,
				this.itemId,
				name,
				key,
				'0.00',
				generateUniqueId(),
				false,
			)
		})
	}

	/** Gets calculated grossPayTotal when isAutoCalculation is "true".
	 * Otherwise gets private value _grossPayTotal.
	 *
	 * @returns {string} grossPayTotal
	 */
	get grossPayTotal() {
		runInAction(() => {
			this.rootStub.isAutoCalculation
				? (this._grossPayTotal = this.getCalculatedGrossPayTotal())
				: null
		})

		return this.rootStub.isAutoCalculation ? this.getCalculatedGrossPayTotal() : this._grossPayTotal
	}

	/** Sets value for private field _grossPayTotal. **/
	set grossPayTotal(v) {
		this._grossPayTotal = v
	}

	/** Gets calculated grossPayYTDTotal when isAutoCalculation is "true".
	 * Otherwise gets private value _grossPayYTDTotal.
	 * @returns {string} grossPayYTDTotal
	 */
	get grossPayYTDTotal() {
		runInAction(() => {
			this.rootStub.isAutoCalculation
				? (this._grossPayYTDTotal = this.getCalculatedGrossPayYTDTotal())
				: null
		})

		return this.rootStub.isAutoCalculation
			? this.getCalculatedGrossPayYTDTotal()
			: this._grossPayYTDTotal
	}

	/** Sets value for private field _grossPayYTDTotal. **/
	set grossPayYTDTotal(v) {
		this._grossPayYTDTotal = v
	}

	/** Gets calculated deductionTotal when isAutoCalculation is "true".
	 * Otherwise gets private value _deductionTotal.
	 * @returns {string} deductionTotal
	 */
	get deductionTotal() {
		runInAction(() => {
			this.rootStub.isAutoCalculation
				? (this._deductionTotal = this.getCalculatedDeductionTotal())
				: null
		})

		return this.rootStub.isAutoCalculation
			? this.getCalculatedDeductionTotal()
			: this._deductionTotal
	}

	/** Sets value for private field _deductionTotal. **/
	set deductionTotal(v) {
		this._deductionTotal = v
	}

	/** Gets calculated deductionYTDTotal when isAutoCalculation is "true".
	 * Otherwise gets private value _deductionYTDTotal.
	 * @returns {string} deductionYTDTotal
	 */
	get deductionYTDTotal() {
		runInAction(() => {
			this.rootStub.isAutoCalculation
				? (this._deductionYTDTotal = this.getCalculatedDeductionYTDTotal())
				: null
		})
		return this.rootStub.isAutoCalculation
			? this.getCalculatedDeductionYTDTotal()
			: this._deductionYTDTotal
	}

	/** Sets value for private field _deductionYTDTotal. **/
	set deductionYTDTotal(v) {
		this._deductionYTDTotal = v
	}

	/** Gets calculated netpayTotal when isAutoCalculation is "true".
	 * Otherwise gets private value _netpayTotal.
	 * @returns {string} netpayTotal
	 */
	get netpayTotal() {
		runInAction(() => {
			this.rootStub.isAutoCalculation ? (this._netpayTotal = this.getCalculatedNetpayTotal()) : null
		})
		return this.rootStub.isAutoCalculation ? this.getCalculatedNetpayTotal() : this._netpayTotal
	}

	/** Sets value for private field _netpayTotal. **/
	set netpayTotal(v) {
		this._netpayTotal = v
	}

	/** Gets calculated netpayYTDTotal when isAutoCalculation is "true".
	 * Otherwise gets private value _netpayYTDTotal.
	 * @returns {string} netpayYTDTotal
	 */
	get netpayYTDTotal() {
		runInAction(() => {
			this.rootStub.isAutoCalculation
				? (this._netpayYTDTotal = this.getCalculatedNetpayYTDTotal())
				: null
		})
		return this.rootStub.isAutoCalculation
			? this.getCalculatedNetpayYTDTotal()
			: this._netpayYTDTotal
	}

	/** Sets value for private field __netpayYTDTotal. **/
	set netpayYTDTotal(v) {
		this._netpayYTDTotal = v
	}

	/** Calculates all total (earnings) fields depending on employmentType and paymentType.
	 *
	 * @returns {string} grossPayTotal
	 */
	getCalculatedGrossPayTotal() {
		let value = 0

		if (this.rootStub.employmentType === 'contractor') {
			value = parseNumber(this.earnings.contractor?.total)
		} else if (this.rootStub.paymentType === 'salary') {
			value = parseNumber(this.earnings.salary?.total)
		} else {
			value = parseNumber(this.earnings.regular?.total)
		}

		const customTotal = this.customEarnings.reduce((sum, item) => sum + parseNumber(item.total), 0)

		return getStringValue(
			value +
				parseNumber(this.earnings.overtime?.total) +
				parseNumber(this.earnings.holiday?.total) +
				parseNumber(this.earnings.vacation?.total) +
				parseNumber(this.earnings.bonus?.total) +
				parseNumber(this.earnings.float?.total) +
				parseNumber(this.earnings.tips?.total) +
				customTotal,
		)
	}

	/** Calculates all ytd (earnings) fields depending on employmentType and paymentType.
	 *
	 * @returns {string} grossPayYTDTotal
	 */
	getCalculatedGrossPayYTDTotal() {
		let value = 0

		if (this.rootStub.employmentType === 'contractor') {
			value = parseNumber(this.earnings.contractor?.ytd)
		} else if (this.rootStub.paymentType === 'salary') {
			value = parseNumber(this.earnings.salary?.ytd)
		} else {
			value = parseNumber(this.earnings.regular?.ytd)
		}

		const customYtdTotal = this.customEarnings.reduce((sum, item) => sum + parseNumber(item.ytd), 0)
		return getStringValue(
			value +
				parseNumber(this.earnings.overtime?.ytd) +
				parseNumber(this.earnings.holiday?.ytd) +
				parseNumber(this.earnings.vacation?.ytd) +
				parseNumber(this.earnings.bonus?.ytd) +
				parseNumber(this.earnings.float?.ytd) +
				parseNumber(this.earnings.tips?.ytd) +
				customYtdTotal,
		)
	}

	/** Calculates all total (earnings) fields depending on employmentType and paymentType.
	 *
	 * @returns {string} deductionTotal
	 */
	getCalculatedDeductionTotal() {
		const customTotal = this.customDeductions.reduce(
			(sum, item) => sum + parseNumber(item.total),
			0,
		)

		const deductionsTotal = [...Object.values(this.deductions)].reduce(
			(sum, item) => sum + parseNumber(item?.total),
			0,
		)

		return getStringValue(deductionsTotal + customTotal)
	}

	/** Calculates all ytd (earnings) fields depending on employmentType and paymentType.
	 *
	 * @returns {string} deductionYTDTotal
	 */
	getCalculatedDeductionYTDTotal() {
		const customYTDTotal = this.customDeductions.reduce(
			(sum, item) => sum + parseNumber(item.ytd),
			0,
		)

		const deductionsYTDTotal = [...Object.values(this.deductions)].reduce(
			(sum, item) => sum + parseNumber(item?.ytd),
			0,
		)

		return getStringValue(deductionsYTDTotal + customYTDTotal)
	}

	getCalculatedNetpayTotal() {
		return getStringValue(parseNumber(this.grossPayTotal) - parseNumber(this.deductionTotal))
	}

	getCalculatedNetpayYTDTotal() {
		return getStringValue(parseNumber(this.grossPayYTDTotal) - parseNumber(this.deductionYTDTotal))
	}

	/** Calculates all tax (deduction) fields using a remote API.
	 *  The calculation will be runs in case if isAutoCalculation is "true" and employmentType is employee.
	 *  After calculation all deductions fields in this store will be filled with the data from response.
	 * @returns {Promise<void>}
	 * @memberof PayStubItemStore
	 */
	calculate = async (): Promise<void> => {
		if (!this.rootStub.isAutoCalculation || this.rootStub.employmentType === 'contractor') return
		this.rootStub.isCalculationLoading = true

		try {
			const res = await apiService.salaryCalculate({
				grossPay: parseNumber(this.grossPayTotal),
				payFrequency: this.rootStub.oftenPaid,
				state: this.rootStub.employeeInfo.state || 'AL',
				grossPayType: 'PAY_PER_PERIOD',
				payDate: this.payDate.getTime(),
				federalFilingStatusType: this.rootStub.employeeInfo.maritalStatus,
				grossPayYTD: 0,
				dependents2020: Number(this.rootStub.employeeInfo.dependentsAmount),
				stateFillingStatus: getStateFillingStatus(
					this.rootStub.employeeInfo.maritalStatus,
				) as unknown as calculator.TStateFillingStatus,
			})
			this.rootStub.errorMessage = ''

			if (res.data.ok && res.data.data) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
				let {stateTaxTypes, timeStamp, ...content} = res.data.data

				if (!stateTaxTypes) stateTaxTypes = []

				const deductionsKeys = Object.keys(this.deductions) as unknown as TDeductionsTypes[]

				deductionsKeys.forEach((key) => {
					const deductionItem = this.deductions[key]
					if (!deductionItem) throw new Error(`Unknown deduction key: ${key}`)

					if (content.hasOwnProperty(key)) {
						const typedContent = content as unknown as {[K: string]: number}
						deductionItem.total = getStringValue(typedContent[key])
						/** To handle stateTaxTypes */
					} else {
						const stateTaxTypesItem = stateTaxTypes.find(
							(stateTaxType) => stateTaxType.name.toLocaleLowerCase() === key,
						)

						deductionItem.total = stateTaxTypesItem?.value ? `${stateTaxTypesItem?.value}` : '0.00'
					}
				})

				this.rootStub.validateCalculation()
			}
			this.rootStub.isCalculationLoading = false
		} catch (err) {
			const error = err as AxiosError
			error.message = error.response?.data.error?.data?.message || error.message
			addBreadcrumb({
				data: error.response?.data?.error?.data,
				message: error.response?.data?.error?.data?.message,
			})
			captureException(error)
			posthogTrack('[Error] Error in async operation', {
				message: (err as Error).message,
				flowFunction: 'PaystubItem.store.calculate',
			})
			this.rootStub.isCalculationLoading = false
			this.rootStub.errorMessage =
				(err as AxiosError<IApiResponseBase>).response?.data.error?.message ||
				'Something went wrong'
		}
	}
}
