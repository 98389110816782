import React, {FC} from 'react'

import cn from 'classnames/bind'

import s from './ErrorPageContent.module.scss'
const cx = cn.bind(s)

interface IErrorPageContentProps {
	title: string
	subtitle: string
	description: string
	button: JSX.Element
	className?: string
}

const ErrorPageContent: FC<IErrorPageContentProps> = ({
	title,
	subtitle,
	description,
	button,
	className,
}) => {
	return (
		<div className={cx('error-content', className)}>
			<div className={cx('error-content__title')}>{title}</div>
			<h2 className={cx('error-content__subtitle')}>{subtitle}</h2>
			<p className={cx('error-content__description')}>{description}</p>
			<div className={cx('error-content__btn-wrapper')}>{button}</div>
		</div>
	)
}

ErrorPageContent.displayName = 'ErrorPageContent'

export default ErrorPageContent
