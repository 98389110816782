import {useCallback, useState} from 'react'

import {addBreadcrumb, captureException, Severity} from '@sentry/nextjs'
import {AxiosError} from 'axios'
import {FirebaseError} from 'firebase/app'

import {posthogTrack} from 'src/utils'

type TFunc = (...attrs: any[]) => Promise<any>

const initialState = {
	isLoading: false,
	isSuccess: false,
	isError: false,
	errorMessage: '',
}

export default function useAsyncFlow<T extends TFunc, R = Awaited<ReturnType<T>>>(flow: T) {
	const [flowState, setFlowState] = useState(initialState)

	const _onStart = useCallback(() => {
		setFlowState({
			...flowState,
			isSuccess: false,
			isLoading: true,
			isError: false,
			errorMessage: '',
		})
	}, [])

	const _onSuccess = useCallback(() => {
		setFlowState({
			...flowState,
			isSuccess: true,
			isLoading: false,
		})
	}, [])

	const _onError = useCallback((errorMessage: string) => {
		setFlowState({
			...flowState,
			isSuccess: false,
			isLoading: false,
			isError: true,
			errorMessage,
		})
	}, [])

	const thunk = useCallback(
		(...attrs: Parameters<T>) => {
			return async ({onSuccess, onError}: {onSuccess?: () => void; onError?: () => void} = {}) => {
				try {
					_onStart()

					const res = (await (flow as T)(...attrs)) as R

					_onSuccess()
					onSuccess && onSuccess()
					return res
				} catch (error) {
					const err = error as Error | FirebaseError | AxiosError<IApiResponseBase>
					let errorMessage = err.message

					if (err instanceof FirebaseError) {
						switch (err.code) {
							case 'auth/too-many-requests':
								errorMessage =
									'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
								break
							case 'auth/user-not-found':
								errorMessage = 'User not found. Please check your email.'
								break
							case 'auth/wrong-password':
								errorMessage = 'Wrong password. Please check your password.'
								break

							default:
								break
						}
					}

					if ('isAxiosError' in err) {
						errorMessage = err.response?.data.error?.message || ''
						if (err.response?.data instanceof Blob) {
							const blobContent = await err.response?.data.text()
							try {
								const errorObj = JSON.parse(blobContent)
								errorMessage = errorObj.error?.message
							} catch (e) {
								errorMessage = blobContent
							}
						}
					}
					err.message = errorMessage
					addBreadcrumb({
						message: errorMessage,
						data: (err as AxiosError).response?.data,
						level: Severity.Log,
					})
					captureException(err)

					_onError(errorMessage || 'Something went wrong')
					if (!onError) {
						posthogTrack('[Error] Error in async operation', {
							message: errorMessage,
							flowFunction: flow.name,
						})
					} else {
						onError()
					}
				}
			}
		},
		[_onError, _onStart, _onSuccess, flow],
	)

	return [thunk, flowState] as const
}
