/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import {BsPrefixProps, BsPrefixRefForwardingComponent} from './helpers'

type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'

type ColOrderNumber = number | '1' | '2' | '3' | '4' | '5'
type ColOrder = ColOrderNumber | 'first' | 'last'
type ColSize = boolean | 'auto' | NumberAttr
type ColSpec = ColSize | {span?: ColSize; offset?: NumberAttr; order?: ColOrder}

export interface ColProps extends BsPrefixProps, React.HTMLAttributes<HTMLElement> {
	xs?: ColSpec
	xxs?: ColSpec
	sm?: ColSpec
	md?: ColSpec
	lg?: ColSpec
	xl?: ColSpec
	xxl?: ColSpec
}

const DEVICE_SIZES = ['xxl', 'xl', 'lg', 'md', 'sm', 'xxs', 'xs'] as const
const colSize = PropTypes.oneOfType([
	PropTypes.bool,
	PropTypes.number,
	PropTypes.string,
	PropTypes.oneOf(['auto']),
])

const stringOrNumber = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

const column = PropTypes.oneOfType([
	colSize,
	PropTypes.shape({
		size: colSize,
		order: stringOrNumber,
		offset: stringOrNumber,
	}),
])

const propTypes = {
	as: PropTypes.elementType,

	/**
	 * The number of columns to span on extra small devices (<375px)
	 *
	 * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
	 */
	xs: column,

	/**
	 * The number of columns to span on extra small devices (≥375px)
	 *
	 * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
	 */
	xxs: column,

	/**
	 * The number of columns to span on small devices (≥576px)
	 *
	 * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
	 */
	sm: column,

	/**
	 * The number of columns to span on medium devices (≥768px)
	 *
	 * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
	 */
	md: column,

	/**
	 * The number of columns to span on large devices (≥992px)
	 *
	 * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
	 */
	lg: column,

	/**
	 * The number of columns to span on extra large devices (≥1200px)
	 *
	 * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
	 */
	xl: column,

	/**
	 * The number of columns to span on extra extra large devices (≥1400px)
	 *
	 * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
	 */
	xxl: column,
}

export interface UseColMetadata {
	as?: React.ElementType
	spans: string[]
}

export function useCol({as, className, ...props}: ColProps): [any, UseColMetadata] {
	const bsPrefix = 'col'

	const spans: string[] = []
	const classes: string[] = []

	DEVICE_SIZES.forEach((brkPoint) => {
		const propValue = props[brkPoint]
		delete props[brkPoint]

		let span: ColSize | undefined
		let offset: NumberAttr | undefined
		let order: ColOrder | undefined

		if (typeof propValue === 'object' && propValue != null) {
			;({span, offset, order} = propValue)
		} else {
			span = propValue
		}

		const infix = brkPoint !== 'xs' ? `-${brkPoint}` : ''

		if (span) spans.push(span === true ? `${bsPrefix}${infix}` : `${bsPrefix}${infix}-${span}`)

		if (order != null) classes.push(`order${infix}-${order}`)
		if (offset != null) classes.push(`offset${infix}-${offset}`)
	})

	return [
		{...props, className: classNames(className, ...spans, ...classes)},
		{
			as,
			spans,
		},
	]
}

const Col: BsPrefixRefForwardingComponent<'div', ColProps> = React.forwardRef<
	HTMLElement,
	ColProps
>(
	// Need to define the default "as" during prop destructuring to be compatible with styled-components github.com/react-bootstrap/react-bootstrap/issues/3595
	(props, ref) => {
		const [{className, ...colProps}, {as: Component = 'div', spans}] = useCol(props)

		return <Component {...colProps} ref={ref} className={classNames(className, !spans.length)} />
	},
)

Col.displayName = 'Col'
Col.propTypes = propTypes

export default Col
