import Form1099HistoryStore from './Form1099History.store'
import InvoiceHistoryStore from './InvoiceHistory.store'
import PaystubHistoryStore from './PaystubHistory.store'
import W2HistoryStore from './W2History.store'

export default class HistoriesStores {
	w2: W2HistoryStore
	invoice: InvoiceHistoryStore
	paystub: PaystubHistoryStore
	'1099': Form1099HistoryStore
	constructor() {
		this.w2 = new W2HistoryStore()
		this.invoice = new InvoiceHistoryStore()
		this.paystub = new PaystubHistoryStore()
		this['1099'] = new Form1099HistoryStore()
	}
}

export type THistoryStores =
	| W2HistoryStore
	| InvoiceHistoryStore
	| PaystubHistoryStore
	| Form1099HistoryStore
