import {configure, makeObservable, observable} from 'mobx'

import AuthStore from './Auth.store'
import DraftStore from './Draft.store'
import Form1099Store from './Form1099/Form1099.store'
import HistoriesStores from './History/Histories.store'
import InvoiceStore from './Invoice/Invoice.store'
import PaymentStore from './Payment.store'
import PayStubStore from './PayStub/PayStub.store'
import W2Store from './W2/W2.store'

configure({
	enforceActions: 'never',
})

export interface IRootStoreHydration {
	invoice?: InvoiceStore
}

export class RootStore {
	paystub: PayStubStore
	invoice: InvoiceStore
	history: HistoriesStores
	auth: AuthStore
	payment: PaymentStore
	form1099: Form1099Store
	w2: W2Store
	draft: DraftStore
	previousRoute: string | null = null

	constructor() {
		this.paystub = new PayStubStore()
		this.invoice = new InvoiceStore(this)
		this.auth = new AuthStore()
		this.payment = new PaymentStore()
		this.form1099 = new Form1099Store()
		this.draft = new DraftStore()
		this.w2 = new W2Store()
		this.history = new HistoriesStores()

		makeObservable(this, {previousRoute: observable})
	}

	setPreviousRoute(route: string) {
		const routesToSkip = ['/auth']

		if (routesToSkip.some((skipRoute) => route.indexOf(skipRoute) > -1)) return
		this.previousRoute = route
	}
}
