import {captureException} from '@sentry/nextjs'
import {AxiosError, AxiosResponse} from 'axios'
import getDayOfYear from 'date-fns/getDayOfYear'
import {makeAutoObservable} from 'mobx'

import apiService from 'services/api.service'
import {
	addDay,
	posthogTrack,
	generateUniqueId,
	getStateFillingStatus,
	getStringValue,
	getWorkingDays,
	parseNumber,
} from 'src/utils'

import {deductions, earnings} from './PayStub.configs'
import PayStubStore from './PayStub.store'

export default class PayStubPrevYtd implements IPayStubPrevYtd {
	rootStub: PayStubStore
	isCalculationValid = true
	prevId: number
	earnings: {
		[key in TEarningsTypes]: IEarningsPrevYtd | undefined
	} = {
		contractor: undefined,
		salary: undefined,
		regular: undefined,
		overtime: undefined,
		holiday: undefined,
		vacation: undefined,
		bonus: undefined,
		float: undefined,
		tips: undefined,
	}
	customEarnings: IEarningsPrevYtd[] = []
	deductions: {
		[key in TDeductionsTypes]: IDeductionsPrevYtd | undefined
	} = {
		medicare: undefined,
		fica: undefined,
		federal: undefined,
		state: undefined,
		sdi: undefined,
		sui: undefined,
		wc: undefined,
		fli: undefined,
		wst: undefined,
		trans: undefined,
	}
	customDeductions: IDeductionsPrevYtd[] = []

	constructor(rootStub: PayStubStore, index: number) {
		makeAutoObservable(this)
		this.rootStub = rootStub
		this.prevId = index

		/** Create default list of earnings. **/
		earnings.forEach(({key, name}) => {
			this.earnings[key] = {
				id: generateUniqueId(),
				name,
				key,
				isCustom: false,
				ytd: '0.00',
			}
		})

		/** Create default list of deductions. **/
		deductions.forEach(({key, name}) => {
			this.deductions[key] = {
				id: generateUniqueId(),
				name,
				key,
				isCustom: false,
				ytd: '0.00',
			}
		})
	}

	/** Gets calculated grossPay
	 * YTDTotal when isPrevYtd is "true".
	 *
	 * @returns {string} grossPayYTDTotal
	 */
	get grossPayYTDTotal() {
		return this.getCalculatedGrossPayYTDTotal()
	}

	/** Calculates all ytd (deductions) fields depending on employmentType and paymentType.
	 *
	 * @returns {string} deductionYTDTotal
	 */
	get deductionYTDTotal() {
		const customTotal = this.customDeductions.reduce((sum, item) => sum + parseNumber(item.ytd), 0)
		const deductionsTotal = [...Object.values(this.deductions)].reduce(
			(sum, item) => sum + parseNumber(item?.ytd),
			0,
		)
		return getStringValue(deductionsTotal + customTotal)
	}

	get netpayYTDTotal() {
		return getStringValue(parseNumber(this.grossPayYTDTotal) - parseNumber(this.deductionYTDTotal))
	}

	/** Set the isCalculationValid field to true  */
	validateCalculation = () => {
		this.isCalculationValid = true
	}

	/** Set the isCalculationValid field to false  */
	unValidateCalculation = () => {
		this.isCalculationValid = false
	}

	/** Calculates all ytd (earnings) fields depending on employmentType and paymentType.
	 *
	 * @returns {string} grossPayYTDTotal
	 */
	getCalculatedGrossPayYTDTotal() {
		let value = 0

		if (this.rootStub.employmentType === 'contractor') {
			value = parseNumber(this.earnings.contractor?.ytd)
		} else if (this.rootStub.paymentType === 'salary') {
			value = parseNumber(this.earnings.salary?.ytd)
		} else {
			value = parseNumber(this.earnings.regular?.ytd)
		}

		const customYtdTotal = this.customEarnings.reduce((sum, item) => sum + parseNumber(item.ytd), 0)

		return getStringValue(
			value +
				parseNumber(this.earnings.overtime?.ytd) +
				parseNumber(this.earnings.holiday?.ytd) +
				parseNumber(this.earnings.vacation?.ytd) +
				parseNumber(this.earnings.bonus?.ytd) +
				parseNumber(this.earnings.float?.ytd) +
				parseNumber(this.earnings.tips?.ytd) +
				customYtdTotal,
		)
	}

	/** Sets default ytd for each earnings. Default ytd equals ytd(from last paystub) subtract total(from last paystub). **/
	setEarningsDefaultPrevYtd() {
		const lastItem = this.rootStub.items[this.rootStub.items.length - 1]
		earnings.forEach(({key}) => {
			const defaultYtd =
				parseNumber(lastItem.earnings[key]?.ytd) - parseNumber(lastItem.earnings[key]?.total)
			this.earnings[key]!.ytd = getStringValue(defaultYtd)
		})
		this.customEarnings.forEach((customPrevEarning) => {
			const earning = lastItem.customEarnings.find(
				(customEarning) => customPrevEarning.id === customEarning.id,
			)
			const defaultYtd = parseNumber(earning?.ytd) - parseNumber(earning?.total)
			customPrevEarning.ytd = getStringValue(defaultYtd)
		})
	}

	/** Calculates all tax (deduction) fields using a remote API.
	 *  The calculation will be runs in case if isAutoCalculation is "true" and employmentType is "employee" and isPrevYtd is "true".
	 *  After calculation all deductions fields in this store will be filled with the data from response.
	 * @returns {Promise<void>}
	 */
	async calculate() {
		this.resetDeductionsValue()
		if (
			!this.rootStub.isAutoCalculation ||
			this.rootStub.employmentType === 'contractor' ||
			!this.rootStub.isPrevYtd
		)
			return

		this.rootStub.isCalculationLoading = true
		const payDate = this.getPayDate()
		const period = this.getPrevYtdPeriod(payDate)
		const grossPay = Number((parseNumber(this.grossPayYTDTotal) / period).toFixed(2))

		let res: AxiosResponse<Api.salaryCalc.IResponse, any> | undefined = undefined
		try {
			res = await apiService.salaryCalculate({
				grossPay,
				payFrequency: this.rootStub.oftenPaid,
				state: this.rootStub.employeeInfo.state || 'AL',
				grossPayType: 'PAY_PER_PERIOD',
				payDate: payDate.getTime(),
				federalFilingStatusType: this.rootStub.employeeInfo.maritalStatus,
				grossPayYTD: 0,
				dependents2020: Number(this.rootStub.employeeInfo.dependentsAmount),
				stateFillingStatus: getStateFillingStatus(
					this.rootStub.employeeInfo.maritalStatus,
				) as unknown as calculator.TStateFillingStatus,
			})

			if (res.data.ok && res.data.data) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const {stateTaxTypes = [], timeStamp, ...content} = res.data.data
				const deductionsKeys = Object.keys(this.deductions) as unknown as TDeductionsTypes[]

				deductionsKeys.forEach((key) => {
					const deductionItem = this.deductions[key]
					if (!deductionItem) throw new Error(`Unknown deduction key: ${key}`)

					if (content.hasOwnProperty(key)) {
						const typedContent = content as unknown as {[K: string]: number}
						deductionItem.ytd = getStringValue(typedContent[key] * period)
						/** To handle stateTaxTypes */
					} else {
						const stateTaxTypesItem = stateTaxTypes.find(
							(stateTaxType) => stateTaxType.name.toLocaleLowerCase() === key,
						)

						deductionItem.ytd = getStringValue(parseNumber(stateTaxTypesItem?.value) * period)
					}
				})
			}
			this.rootStub.errorMessage = ''
			this.validateCalculation()
		} catch (error) {
			captureException(error)
			posthogTrack('[Error] Error in async operation', {
				message: (error as Error).message,
				flowFunction: 'PayStubPrevYtd.store.calculate',
			})
			this.rootStub.errorMessage =
				(error as AxiosError<IApiResponseBase>).response?.data.error?.message ||
				'Something went wrong'
		} finally {
			this.rootStub.isCalculationLoading = false
		}
	}

	/** Resets all deductions ytd if employmentType is "contractor". **/
	resetDeductionsValue() {
		if (this.rootStub.employmentType === 'contractor') {
			deductions.forEach(({key}) => {
				this.deductions[key]!.ytd = '0.00'
			})
			this.customDeductions.forEach((customDeduction) => {
				customDeduction.ytd = '0.00'
			})
		}
	}

	/** Gets pay date for prev ytd. It depends on last paystub item pay date.**/
	getPayDate() {
		const lastItem = this.rootStub.items[this.rootStub.items.length - 1]
		return addDay(this.rootStub.getDayByOftenPaid(new Date(lastItem.payDate), false), -1)
	}

	/** Gets prev ytd period according to different often paid options. It depends on pay date.**/
	getPrevYtdPeriod(payDate: Date) {
		const fullYear = payDate.getFullYear()
		const dayOfYear = getDayOfYear(new Date(payDate))
		switch (this.rootStub.oftenPaid) {
			case 'DAILY':
				return getWorkingDays(new Date(fullYear, 0, 1), new Date(payDate))
			case 'WEEKLY':
				return this.rootStub.getPeriod(dayOfYear)
			case 'BI_WEEKLY':
				return this.rootStub.getPeriod(dayOfYear)
			case 'SEMI_MONTHLY':
				return this.rootStub.getPeriod(dayOfYear)
			case 'MONTHLY':
				return payDate.getMonth() + 1
			case 'QUARTERLY':
				return this.rootStub.getQuarterlyPeriod(new Date(payDate))
			case 'SEMI_ANNUAL':
				return this.rootStub.getSemiAnnuallyPeriod(new Date(payDate))
			default:
				return 1
		}
	}
}
