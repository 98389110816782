const getOptions = <T>(p: readonly TOption<T>[]) => p

export const autoCalculatorOptions = getOptions([
	{value: 'true', name: 'On'},
	{value: 'false', name: 'Off'},
] as const)
export type TAutoCalculator = typeof autoCalculatorOptions[number]['value']

export const employmentTypeOptions = getOptions([
	{value: 'employee', name: 'Employee'},
	{value: 'contractor', name: 'Contractor'},
] as const)
export type TEmployment = typeof employmentTypeOptions[number]['value']

export const paymentTypeOptions = getOptions([
	{value: 'hourly', name: 'Hourly'},
	{value: 'salary', name: 'Salary'},
] as const)
export type TPayment = typeof paymentTypeOptions[number]['value']

export const oftenPaidOptions = getOptions([
	{value: 'DAILY', name: 'Daily'},
	{value: 'WEEKLY', name: 'Weekly (ex: every Friday)'},
	{value: 'BI_WEEKLY', name: 'Bi-Weekly (ex: every other Wednesday)'},
	{value: 'SEMI_MONTHLY', name: 'Semi-Monthly (ex: 1st and 15th)'},
	{value: 'MONTHLY', name: 'Monthly (ex: 1st of month only)'},
	{value: 'QUARTERLY', name: 'Quarterly'},
	{value: 'SEMI_ANNUAL', name: 'Semi-Annually'},
	{value: 'ANNUAL', name: 'Annually'},
] as const)
export type TOftenPaid = typeof oftenPaidOptions[number]['value']

export const yesNoOptions = getOptions([
	{value: 'false', name: 'No'},
	{value: 'true', name: 'Yes'},
] as const)
export type TYesNo = typeof yesNoOptions[number]['value']

export const payStubNumberOptions = getOptions([
	{value: '1', name: '1'},
	{value: '2', name: '2'},
	{value: '3', name: '3'},
	{value: '4', name: '4'},
	{value: '5', name: '5'},
	{value: '6', name: '6'},
	{value: '7', name: '7'},
	{value: '8', name: '8'},
	{value: '9', name: '9'},
	{value: '10', name: '10'},
	{value: '11', name: '11'},
	{value: '12', name: '12'},
	{value: '13', name: '13'},
	{value: '14', name: '14'},
	{value: '15', name: '15'},
	{value: '16', name: '16'},
	{value: '17', name: '17'},
	{value: '18', name: '18'},
] as const)
export type TPayStubsNumber = typeof payStubNumberOptions[number]['value']

export const maritalOptions = getOptions([
	{value: 'SINGLE', name: 'Single'},
	{value: 'MARRIED', name: 'Married'},
	{value: 'HEAD_OF_HOUSEHOLD', name: 'Head of household'},
] as const)

export const exemptions = getOptions([
	{value: '0', name: '0'},
	{value: '1', name: '1'},
	{value: '2', name: '2'},
	{value: '3', name: '3'},
	{value: '4', name: '4'},
	{value: '5', name: '5'},
	{value: '6', name: '6'},
	{value: '7', name: '7'},
	{value: '8', name: '8'},
	{value: '9', name: '9'},
] as const)

export const deductions: Array<{key: TDeductionsTypes; name: string}> = [
	{key: 'medicare', name: 'FICA-Medicare'},
	{key: 'fica', name: 'FICA-Social Security'},
	{key: 'federal', name: 'Federal tax'},
	{key: 'state', name: 'State tax'},
	{key: 'sdi', name: 'SDI'},
	{key: 'sui', name: 'SUI'},
	{key: 'wc', name: 'WC'},
	{key: 'fli', name: 'FLI'},
	{key: 'wst', name: 'WST'},
	{key: 'trans', name: 'TRANS'},
]

export const earnings: Array<{key: TEarningsTypes; name: string}> = [
	{key: 'contractor', name: 'Contractor'},
	{key: 'salary', name: 'Salary'},
	{key: 'regular', name: 'Regular'},
	{key: 'overtime', name: 'Overtime'},
	{key: 'holiday', name: 'Holiday'},
	{key: 'vacation', name: 'Vacation'},
	{key: 'bonus', name: 'Bonus'},
	{key: 'float', name: 'Float'},
	{key: 'tips', name: 'Tips'},
]
