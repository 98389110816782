/* eslint-disable @typescript-eslint/no-explicit-any */
export class BaseException extends Error {
	status = 500
	isApiException: boolean
	data: unknown

	constructor(message: string, status = 400, data: any = null) {
		super(message)
		this.status = status
		this.data = data
		this.isApiException = true
	}
}
export const BASE_ERROR_MESSAGES = {
	400: 'Bad Request',
	401: 'Unauthorized',
	402: 'Payment Required',
	403: 'Forbidden',
	404: 'Not found',
	405: 'Method Not Allowed',
	422: 'Unprocessable entity',
	429: 'Too Many Requests',
	500: 'Internal Server Error',
}
export class UnprocessableException extends BaseException {
	constructor(message = BASE_ERROR_MESSAGES[422], data: any = null) {
		super(message, 422, data)
		this.name = 'Unprocessable entity'
	}
}

export class NotFoundException extends BaseException {
	constructor(message = BASE_ERROR_MESSAGES[404], data: any = null) {
		super(message, 404, data)
		this.name = 'Not found'
	}
}

export class NoAccessException extends BaseException {
	constructor(message = BASE_ERROR_MESSAGES[403], data: any = null) {
		super(message, 403, data)
		this.name = 'No Access'
	}
}
export class NotAuthorizedException extends BaseException {
	constructor(message = BASE_ERROR_MESSAGES[401], data: any = null) {
		super(message, 401, data)
		this.name = BASE_ERROR_MESSAGES[401]
	}
}

export class BadRequestException extends BaseException {
	constructor(message = BASE_ERROR_MESSAGES[400], data: any = null) {
		super(message, 400, data)
		this.name = BASE_ERROR_MESSAGES[400]
	}
}

export class TooManyRequestsException extends BaseException {
	constructor(message = BASE_ERROR_MESSAGES[429], data: any = null) {
		super(message, 429, data)
		this.name = BASE_ERROR_MESSAGES[429]
	}
}

export class ServerException extends BaseException {
	constructor(message = BASE_ERROR_MESSAGES[500], data: any = null) {
		super(message, 500, data)
		this.name = BASE_ERROR_MESSAGES[500]
	}
}
