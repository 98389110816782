import React, {FC, useState} from 'react'

import cn from 'classnames/bind'

import {AccessibilityScript} from './AccessibilityScript'
import s from './EqualwebButton.module.scss'
const cx = cn.bind(s)

const EqualwebButton = ({className}: {className?: string}) => {
	const [isLoadA11Y, setIsLoadA11Y] = useState(false)

	const handleA11yClick = () => {
		if (window.interdeal) {
			window.interdeal?.a11y?.openMenu()
		} else {
			setIsLoadA11Y(true)
		}
	}

	return (
		<>
			<button
				className={cx('wrapper', className)}
				aria-label="Open accessiBe: accessibility options, statement and help"
				onClick={handleA11yClick}>
				<svg
					fill="white"
					width="30px"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					viewBox="0 0 100 131.3">
					<path d="M71.6,131.3c1,0,2.1-0.3,3.1-0.8c3.9-1.8,5.5-6.2,3.6-10.1c0,0-14.3-32.7-16.9-44.7c-1-4.2-1.6-15.3-1.8-20.5c0-1.8,1-3.4,2.6-3.9l32-9.6c3.9-1,6.2-5.5,5.2-9.4c-1-3.9-5.5-6.2-9.4-5.2c0,0-29.6,9.6-40.3,9.6c-10.4,0-39.8-9.4-39.8-9.4c-3.9-1-8.3,0.8-9.6,4.7c-1.3,4.2,1,8.6,5.2,9.6l32,9.6c1.6,0.5,2.9,2.1,2.6,3.9c-0.3,5.2-0.8,16.4-1.8,20.5c-2.6,12-16.9,44.7-16.9,44.7c-1.8,3.9,0,8.3,3.6,10.1c1,0.5,2.1,0.8,3.1,0.8c2.9,0,5.7-1.6,6.8-4.4l15.3-31.2l14.6,31.4C66.1,129.7,68.7,131.3,71.6,131.3z"></path>
					<circle stroke="white" cx="50.3" cy="14.6" r="14.6"></circle>
				</svg>
			</button>
			{isLoadA11Y && <AccessibilityScript />}
		</>
	)
}

EqualwebButton.displayName = 'EqualwebButton'

export default EqualwebButton
