import {makeAutoObservable} from 'mobx'

import {parseNumber} from 'src/utils'

import {RootStore} from '../Root.store'

export default class InvoiceItemStore implements IInvoiceItem {
	root: RootStore
	description = ''
	rate = ''
	qty = '1'
	withTax = true
	details = ''

	constructor(root: RootStore) {
		makeAutoObservable(this, {root: false})
		this.root = root
	}

	get amount() {
		const value = parseNumber(this.qty) * parseNumber(this.rate) || 0
		return value
	}

	get discountedAmount() {
		const discountRateNumber = parseNumber(this.root.invoice.discountRate) || 0
		if (discountRateNumber <= 0) return this.amount

		if (this.root.invoice.discountType === 'percent') {
			const discount = (this.amount * discountRateNumber) / 100
			return this.amount - discount
		}

		if (this.root.invoice.discountType === 'flat_amount') {
			const discount = discountRateNumber / this.root.invoice.items.length
			return this.amount - discount
		}

		return this.amount
	}
}
