import {useEffect} from 'react'

export const AccessibilityScript = () => {
	useEffect(() => {
		if (window.interdeal) return

		window.interdeal = {
			sitekey: '251547ffc68cb94b179a07ce6e568e85',
			Position: 'Left',
			Menulang: 'EN',
			domains: {
				js: 'https://cdn.equalweb.com/',
				acc: 'https://access.equalweb.com/',
			},
			btnStyle: {
				vPosition: ['bottom-left', 'bottom-left'],
				scale: ['0.5', '0.5'],
				color: {
					main: '#1876c9',
					second: '#ffffff',
				},
				icon: {
					type: 11,
					shape: 'circle',
				},
			},
		}

		const script = document.createElement('script')
		script.src = 'https://cdn.equalweb.com/core/4.6.12/accessibility.js'
		script.integrity =
			'sha512-tbmzeMlPGqU0SEFXwKxqHdaa9SnF4EjTai5LCahOcN9KSTIo9LRscxtii6TZ0MSooxl73IZg2cj6klk8wBm0hg=='
		script.crossOrigin = 'anonymous'
		script.setAttribute('data-cfasync', 'true')
		document.body.appendChild(script)
		script.addEventListener('load', () => {
			setTimeout(() => {
				try {
					window.interdeal?.a11y?.openMenu()
				} catch (error) {}
			}, 1000)
		})
	}, [])

	return <></>
}
